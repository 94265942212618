import angular from 'angular';
import { localStorage } from '../utils';

const module = angular.module('whyline.controllers.users', [
  'whyline.constants',
]);

const UsersController = (
  ADMIN_URL,
) => {
  const token = localStorage.get('token');
  const url = new URL(`${ADMIN_URL}external`);
  url.searchParams.append('token', token);
  url.searchParams.append('url', `/users`);
  console.log(url.toString());
  location.href = url;
};

module.exports = module.controller('UsersController', UsersController);
