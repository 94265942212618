import angular from 'angular';
import { get } from 'lodash';
import './modals/devices';
import '../services/device';
import '../services/errors';
import '../services/place';
import '../app.constants';

const module = angular.module('whyline.controllers.devices', [
  'whyline.modals.devices',
  'whyline.services.device',
  'whyline.services.place',
  'whyline.services.errors',
  'whyline.constants',
]);


const DevicesController = ($scope, $uibModal, $translate, DeviceService, NotificationService, dialog) => {
   DeviceService.GetLinked();
   $scope.devices = DeviceService.GetLinked();

    $scope.open = deviceId  => {
        $uibModal.open({
          templateUrl: '/templates/components/modals/device-modal.html',
          size: 'lg',
          controller: 'DeviceModalController',
          resolve: {
            deviceId: () => deviceId
          }
        });
      };

      $scope.remove = (deviceId, name) => {
        let n = name || '';
        let text = $translate.instant('device_{name}_remove_sure').replace('{name}', n);
        dialog.confirm(text)
          .then(() => {
            return DeviceService.UnlinkFromPlace(deviceId)
                .then(() => {
                    let n = name || '';
                    let text = $translate.instant('device_{name}_remove_succ').replace('{name}', n);
                    NotificationService.Success(text);
                })
                .catch();


          });
      };

};

module.exports = module.controller('DevicesController', DevicesController);
