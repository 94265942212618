import angular from 'angular';
import { localStorage } from '../../utils';

const module = angular.module('whyline.controllers.box-select', [
  'whyline.services.box',
]);

const BoxSelectController = ($state, $scope, $uibModalInstance, $rootScope, BoxService) => {

  $rootScope.$on('box:reporting', (event, boxId) => {
    const selectedBox = localStorage.get('selectedBox', null);
    if (!selectedBox || selectedBox._id !== boxId) {
      const box = $scope.boxes.find((b) => b._id === boxId);
      if (box) {
        box.taken = true;
      }
      if (box && $scope.selectedBox && box._id === $scope.selectedBox._id) {
        $scope.selectedBox = $scope.boxes.find((b) => !b.taken)
      }
    }
  });

  localStorage.get('user').then(user => {
    BoxService.GetAllEnabledByPlaceId(user._id, true)
    .then(res => {
      $scope.boxes = res;
      $scope.boxes.unshift({
        name: '',
        value: ''
      });
    });
  });



  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.confirm = () => {
    BoxService.SetBox(!$scope.selectedBox ? null : $scope.selectedBox);
    $rootScope.$emit('selectedBox');
    $uibModalInstance.close('cancel');
  };

  $scope.handleSelectChange = selected => {
    if (selected.name) {
      $scope.selectedBox = selected;
    }
  };
};

module.exports = module.controller('BoxSelectController', BoxSelectController);
