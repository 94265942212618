import angular from 'angular';
import { localStorage } from '../utils';
import './modals/totems';

const module = angular.module('whyline.controllers.totems', [
  'whyline.modals.totems',
  'whyline.constants',
]);

const TotemsController = (
  $state,
  ADMIN_URL,
) => {
  const organizationId = $state.params.organizationId;
  const placeId = $state.params.placeId;
  const token = localStorage.get('token');
  const url = new URL(`${ADMIN_URL}external`);
  url.searchParams.append('token', token);
  url.searchParams.append('url', `/organizations/${organizationId}/places/${placeId}/totems`);
  location.href = url;
};

module.exports = module.controller('TotemsController', TotemsController);
