import angular from 'angular';
import { localStorage } from '../../utils';
import '../../app.constants';
import '../../services/globals';
import '../../services/organization';
import '../../services/place';
import '../../services/user';
import '../../services/redirect-to-new-version';
import '../../services/errors';
import '../../resources/resource';
import '../modals/places';

const module = angular.module('whyline.controllers.places.list', [
  'whyline.constants',
]);

const PlacesListController = (
  $state,
  ADMIN_URL,
) => {
  const organizationId = $state.params.organizationId;
  const token = localStorage.get('token');
  const url = new URL(`${ADMIN_URL}external`);
  url.searchParams.append('token', token);
  url.searchParams.append('url', `/organizations/${organizationId}/places`);
  console.log(url.toString());
  location.href = url;
};

module.exports = module.controller('PlacesListController', PlacesListController);
