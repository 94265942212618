import angular from 'angular';
import { Promise } from 'es6-promise';
import _ from 'lodash';
import './../app.constants';
import './subscription';
import './line';
import './area';
import { formatAwt } from '../utils';

const module = angular.module('whyline.services.now-serving', [
  'whyline.services.subscription',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.constants',
]);

let API_URL;
let $window; // @TODO: improve
let $http;
let NotificationService;
let $timeout;
let LineService;
let AreaService;
let UserService;
let ErrorService;
let socketPromise;
let SubscriptionService;
let $translate;

const handleError = knowErrors => err => {
  if(err && [401, 403, 404].indexOf(err.status) >= 0 && !(knowErrors.indexOf(err.data) >= 0)) {
    $timeout(() => {
      $window.location.reload();
    }, 2000);
  }
  throw err;
};

class NowServingService {
  static $inject = ['API_URL', '$http', '$window', '$timeout', 'LineService', 'AreaService', 'UserService', 'SubscriptionService', 'NotificationService', 'ErrorService', 'Socket', '$translate'];

  constructor(injectedAPI, injected$http, injected$window, injected$timeout, injectedLine, injectedArea, injectedUser, injectedSubscriptionService, injectedNotificationService, injectedErrorService, Socket, injected$Translate) {
    API_URL = injectedAPI;
    $http = injected$http;
    $window = injected$window;
    $timeout = injected$timeout;
    LineService = injectedLine;
    AreaService = injectedArea;
    UserService = injectedUser;
    ErrorService = injectedErrorService;
    socketPromise = Socket;
    SubscriptionService = injectedSubscriptionService;
    NotificationService = injectedNotificationService;
    $translate = injected$Translate;
  }

  GetPlaceWithLinesAndAreas(placeId) {
    const currentUser = UserService.GetCurrent();

    let areasIds = [];
    let linesIds = [];
    let promisesAll = [];

    if(UserService.IsCoordinator(placeId)) {
      // COORDINATOR: Get all Lines and Areas in Place
      promisesAll.push(
        AreaService.GetAllAsPromise()
          .then(results => _.values(results)),
        LineService.GetAllAsPromise()
            .then(results => _.values(results))
      );
    } else {
      // REGULAR USER: Walk through roles to get Lines and Areas Ids allowed for user
      const subroles = _.get(currentUser, `roles[${placeId}].subroles`);
      if(subroles) {
        areasIds = _.map(_.filter(subroles, ['type', 'area']), '_id');
        linesIds = _.map(_.filter(subroles, ['type', 'line']), '_id');
      }

      // SUPERVISOR: also check if current REGULAR USER is supervisor of some area,
      // and if it is, get all Lines in that Area
      const supervisorAreasFromRoles = UserService.GetSupervisorAreas(placeId);
      const supervisorAreasIds = _.map(supervisorAreasFromRoles, '_id');
      if(supervisorAreasIds.length) {
        const supervisorAreas = AreaService.FindByIds(supervisorAreasIds);
        _.forEach(supervisorAreas, area => {
          _.forEach(area.lines, lineId => {
            linesIds.push(lineId);
          });
          //linesIds = _.concat(linesIds, area.lines);
        });
      }

      // Once we have all Line and Area Ids, we find them all
      if(areasIds.length) {
        promisesAll.push(
          AreaService.FindByIds(areasIds, {promise: true})
            // only return PROCESSES (@TODO: return all areas when Groups is ready)
            .then(results => _.values(results)),
        );
      }

      if(linesIds.length) {
        promisesAll.push(
          LineService.FindByIds(linesIds, {promise: true, force: true})
          .then(results => _.values(results))
        );
      }
    }

    return Promise.all(promisesAll)
    .then(results => results.reduce(
      (prev, current) => prev.concat(current),
      []
    ))
    .then(linesAndAreas => linesAndAreas.map(queue => {
      queue.averageWaitingByNumberWithFormat = formatAwt(queue.averageWaitingByNumber);
      queue.waitingPeople = queue.waitingPeople || 0;
      return queue;
    }))
    .then(value => value)
    .catch(handleError(ErrorService.KnowErrors));
  }

  Enqueue(person, queue) {
    return $http.post(`${API_URL}subscription/?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      person
    })
    .catch(handleError(ErrorService.KnowErrors));
  }

  CallTo(subscriptionId, queue) {
    switch (true) {
      case queue.type === 'line':
        return LineService.CallTo(subscriptionId, queue._id);
      case queue.type === 'area' && !queue.ordered:
        return AreaService.CallTo(subscriptionId, queue._id);
      default:
        return NotificationService.Error($translate.instant('err_queue_subscription_specific'), 'Error');
    }
  }

  CallNext(queue) {
    switch (true) {
      case queue.type === 'line':
        return LineService.CallNext(queue._id);
      case queue.type === 'area' && !queue.ordered:
        return AreaService.CallNext(queue._id);
      default:
        return NotificationService.Error($translate.instant('err_queue_subscription_next'), 'Error');
    }
  }

  CompleteSubscription(subscriptionId, queue) {
    return $http.patch(`${API_URL}subscription/${subscriptionId}?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      status: 'completed'
    })
    .catch(handleError(ErrorService.KnowErrors));
  }

  IncompleteSubscription(subscriptionId, queue) {
    return $http.patch(`${API_URL}subscription/${subscriptionId}?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      status: 'incomplete'
    })
    .catch(handleError(ErrorService.KnowErrors));
  }

  NotPresentSubscription(subscriptionId, queue) {
    return $http.patch(`${API_URL}subscription/${subscriptionId}?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      status: 'canceled',
      reason: 'not-present'
    })
    .catch(handleError(ErrorService.KnowErrors));
  }

  CancelSubscription(subscriptionId, queue) {
    return $http.patch(`${API_URL}subscription?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      status: 'cancel',
      reason: 'not-present'
    }).catch(handleError(ErrorService.KnowErrors));
  }

  ForwardSubscription(subscriptionId, queue, destination, areaId) {
    const destinationId = destination ? `lineIdDest=${destination}` : '';
    const areaQuery = areaId ? `&areaId=${areaId}` : '';
    let lineQuery;
    if(queue.type === 'line') {
      lineQuery = `&lineId=${queue._id}`;
    } else if(!areaQuery) {
      lineQuery = `&areaId=${queue._id}`;
    }
    return $http.post(`${API_URL}subscription/${subscriptionId}/forward?${destinationId}${areaQuery}${lineQuery}`)
    .catch(handleError(ErrorService.KnowErrors));
  }

  DeleteSubscription(subscriptionId, queue) {
    return $http.delete(`${API_URL}subscription/${subscriptionId}?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`)
    .catch(handleError(ErrorService.KnowErrors));
  }

  RemoveSubscription(subscriptionId, queue) {
    return SubscriptionService.Remove(subscriptionId, queue);
  }
}

module.exports = module.service('NowServingService', NowServingService);
