import angular from 'angular';
import { localStorage } from '../utils';
import './modals/display';

const module = angular.module('whyline.controllers.displays', [
  'whyline.modals.display',
  'whyline.constants',
]);

const DisplaysController = (
  $state,
  ADMIN_URL,
) => {
  const organizationId = $state.params.organizationId;
  const placeId = $state.params.placeId;
  const token = localStorage.get('token');
  const url = new URL(`${ADMIN_URL}external`);
  url.searchParams.append('token', token);
  url.searchParams.append('url', `/organizations/${organizationId}/places/${placeId}/displays`);
  location.href = url;
};

module.exports = module.controller('DisplaysController', DisplaysController);
