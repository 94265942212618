import angular from 'angular';
import { debounce, isUndefined, get, pull } from 'lodash';
import '../../app.constants';
import '../../services/category';
import '../../services/place';
import '../../services/globals';
import '../../services/user';
import '../../services/place-messages';
import '../../services/errors';
import '../../services/country';
import '../../services/location';
import '../../services/notifications';
import '../../resources/resource';
import '../modals/places';

const module = angular.module('whyline.controllers.places.edit', [
  'whyline.constants',
  'whyline.services.category',
  'whyline.services.place',
  'whyline.services.place-messages',
  'whyline.services.globals',
  'whyline.services.country',
  'whyline.services.user',
  'whyline.services.errors',
  'whyline.services.timezone',
  'whyline.services.location',
  'whyline.services.notifications',
  'whyline.resources.resource',
  'whyline.modals.place',
]);

const PlacesEditController = (SAFE_STATES, $scope, $translate, $state, $timeout, PlaceService, ErrorService, TimezoneService, NotificationService, LocationService, dialog, PlaceMessagesService, CountryService) => {
  /*@ngInject*/

  const placeId = $state.params.id;
  let lastShowQrCode;
  $scope.disableShowQr = false;
  $scope.disabledQrText = '';

  $scope.selectedDefaultIdentification = {
    value: undefined,
  };

  function isFreePlace() {
    const place = $scope.place;
    return place && place.plan && place.plan.type && place.plan.type === 'free';
  }

  function isBaPlace() {
    return $scope.place.enterpriseType === 'ba';
  }

  function isMacroPlace() {
    return $scope.place.enterpriseType === 'macro';
  }

  function isWhylinePlace() {
    return $scope.place.enterpriseType === 'whyline';
  }

  $scope.onPlanChanged = () => {
    $scope.place.plan.name = '';
  };

  $scope.setDefaultIndentificationsList = () => {
    const currentIdentifications = $scope.place.requirements.identifications;
    $scope.availableDefaultIdentifications = currentIdentifications.filter(id => id.show);
    $scope.selectedDefaultIdentification.value = currentIdentifications.find(id => id.default) || currentIdentifications.find(id => id.empty);
  };

  $scope.updateDefaultIdentification = () => {
    const defaultKey = get($scope.selectedDefaultIdentification, 'value.key', undefined);

    const identifications = $scope.place.requirements.identifications;

    identifications.forEach(id => {
      id.default = false;
      return id;
    });

    if (!defaultKey) {
      return;
    }

    const defaultId = identifications.find(id => id.key === defaultKey);

    if (defaultId) {
      defaultId.default = true;
    }
  };

  function placeIdentificationOrDefault(placeIdentifications, identification) {
    const placeIdentification = placeIdentifications.find(p => p.key === identification.key);
    return Object.assign({}, identification, placeIdentification);
  }

  function mergeCountryIdentifications(countryIdentifications, placeIdentifications) {
    return countryIdentifications.map(identification => {
      identification.show = identification.selected;
      Reflect.deleteProperty(identification, 'selected');
      return placeIdentificationOrDefault(placeIdentifications, identification);
    });
  }

  async function getCountryByPlace(place) {
    const location = (await LocationService.Find(place.info.address))[0];

    const countryFound = await CountryService.FindByCode(location.code);

    return countryFound || await CountryService.Create({ code: location.code, name: location.country });
  }

  /**
   * Define si la configuracion del QR se puede cambiar o es inhabilitada.
   * Esto puede depender tanto del tipo de place como el nivel del plan
   */
  function resolveAvailableQRConfig() {
    const isBa = isBaPlace();
    const isFree = isFreePlace();
    const isMacro = isMacroPlace();
    const isWhyline = isWhylinePlace();

    if (isWhyline && !isFree) {
      $scope.disableShowQr = false;
    } else {
      $scope.disableShowQr = true;
    }

    if (isFree) {
      $scope.disabledQrText = $translate.instant('free_plan_place');
    }

    if (isBa) {
      $scope.disabledQrText = $translate.instant('place_is_ba');
    }

    if (isMacro) {
      $scope.disabledQrText = $translate.instant('place_is_macro');
    }
  }

  /**
   * Fuerza el valor de la configuracion del showQRCode segun el tipo de place/plan
   */
  function forceQRConfig() {
    if ($scope.place && $scope.place.mobileAppConfig) {
      switch ($scope.place.enterpriseType) {
        case 'ba':
          $scope.place.mobileAppConfig.showQRCode = true;
          break;
        case 'whyline':
          $scope.place.mobileAppConfig.showQRCode = isFreePlace() ? false : lastShowQrCode;
          break;
        case 'macro':
          $scope.place.mobileAppConfig.showQRCode = false;
          break;
      }
    }
    resolveAvailableQRConfig();
  }

  $scope.onShowQrCodeChange = function (value) {
    lastShowQrCode = value;
  };

  $scope.onEnterpriseChange = function (value) {
    $scope.place.configuration.canConfirmAppointments = value === 'ba';
    $scope.place.configuration.canEditAppointments = value !== 'ba';
    $scope.place.configuration.boxLabelRequired = value === 'ba';
    forceQRConfig();
    resolveAvailableQRConfig();
  };

  $scope.tabs = [
    $translate.instant('tab_general'),
    $translate.instant('tab_localization'),
    $translate.instant('tab_images'),
    $translate.instant('tab_videos'),
    $translate.instant('tab_messages'),
    $translate.instant('tab_config'),
    $translate.instant('save'),
    $translate.instant('exit'),
  ];

  $scope.isSaveTab = tab => $translate.instant('save') === tab;

  $scope.isExitTab = tab => $translate.instant('exit') === tab;

  $scope.activeTab = 0;

  $scope.setActiveTab = index => {
    if ($translate.instant('save') === $scope.tabs[index]) {
      return $scope.save();
    }
    if ($translate.instant('exit') === $scope.tabs[index]) {
      return $state.go(SAFE_STATES.placesList);
    }
    $scope.activeTab = index;
  };

  $scope.placeAddress = {
    address: '',
  };

  $timeout(() => {
    $scope.currentLanguage = $translate.use();
  }, 2000);

  const aliasTypes = PlaceService.GetAliasTypes();
  aliasTypes
    .then((aliasTypes) => {
      $timeout(() => {
        $scope.aliasTypes = aliasTypes ? aliasTypes : [];
        if (placeId) {
          PlaceService.GetOneAsPromiseFromServer(placeId)
            .then(place => {
              $timeout(async () => {
                $scope.placeName = place.name;
                $scope.place = PlaceService.Copy(place);
                $scope.place.locationAsString = [place.location.lat, place.location.lng];
                $scope.place.configuration.canCloneAndForward = !!place.configuration.canCloneAndForward;
                $scope.place.configuration.canConfirmAppointments = !!place.configuration.canConfirmAppointments;
                $scope.place.configuration.canConfirmSpontaneous = !!place.configuration.canConfirmSpontaneous;
                $scope.place.configuration.canEditAppointments = place.configuration.canEditAppointments || place.enterpriseType !== 'ba';
                $scope.place.configuration.canGiveAppointments = !!place.configuration.canGiveAppointments;
                $scope.place.configuration.canSkipProcessOrder = !!place.configuration.canSkipProcessOrder;
                $scope.place.configuration.canViewTotemToggles = !!place.configuration.canViewTotemToggles;
                $scope.place.configuration.requeue.enabled = place.configuration.requeue.enabled;
                $scope.place.configuration.retained.enabled = place.configuration.retained.enabled;
                $scope.place.configuration.requireAppointmentConfirmation = !!place.configuration.requireAppointmentConfirmation;
                $scope.place.configuration.showWaitingInLineInPlace = place.configuration.showWaitingInLineInPlace;
                $scope.selectedTimezone = place.timezone;
                $scope.selectedLanguage = place.language;
                $scope.checkInCode = place.checkInCode;
                $scope.place.configuration.checkInTolerance = place.configuration.checkInTolerance;
                lastShowQrCode = $scope.place.mobileAppConfig ? place.mobileAppConfig.showQRCode : true;
                $scope.place.mobileAppConfig.showPeopleAheadAfterCheckIn = place.mobileAppConfig.showPeopleAheadAfterCheckIn;
                resolveAvailableQRConfig();

                const country = await getCountryByPlace($scope.place);
                $scope.place.location.country = country._id;
                $scope.place.location.countryCode = country.code;
                $scope.country = country.name;
                $scope.place.requirements.identifications = mergeCountryIdentifications(country.identifications, place.requirements.identifications);

                $scope.placeIdentifications = [...$scope.place.requirements.identifications];

                $scope.setDefaultIndentificationsList();
              });
            })
            .catch(ErrorService.handler);
        } else {
          // Init place scope
          $scope.place = {
            name: '',
            hasEnterprise: true,
            enterpriseType: 'ba', // TBR when filacero gets implemented.
            language: 'es',
            categories: [],
            branchId: '',
            enabledForMobileApp: false,
            hiddenInMobileApp: false,
            configuration: {
              aliasType: 'fullNameAlias',
              boxLabelRequired: false,
              canCloneAndForward: false,
              canConfirmAppointments: false,
              canConfirmSpontaneous: false,
              canProcessAppointment: false,
              requireAppointmentConfirmation: false,
              canEditAppointments: true,
              canGiveAppointments: false,
              canSkipProcessOrder: false,
              canViewTotemToggles: false,
              checkInTolerance: 2,
              closedMessage: '',
              requeue: {
                enabled: false,
                limit: {
                  in: {
                    minutes: 60,
                  },
                },
              },
              retained: {
                enabled: false,
                days: 90
              },
              showWorkingDay: false,
              showWaitingInLineInPlace: true,
            },
            timezone: null,
            requirements: {
              gender: false,
              phoneNumber: false,
              address: false,
              birthdate: false,
              identifications: [
                {
                  default: true,
                  inputType: 'text',
                  key: 'id',
                  name: 'Identification',
                  selected: true,
                  validator: '(id) => typeof id !== "undefined"',
                }
              ],
            },
            plan: {
              hasLimits: true,
              maxAreas: 0,
              maxDisplays: 1,
              maxLines: 5,
              maxSectors: 0,
              maxTotems: 1,
              name: 'Merchant-free',
              type: 'free',
            },
            location: {},
            openingDetails: {},
            info: {
              phones: [],
              photos: [],
              address: ''
            },
            sectors: [],
            areas: [],
            users: [],
            displays: [],
            lines: [],
            geoLocation: [],
            videos: [],
            checkInCode: '',
            mobileAppConfig: {
              autoCheckIn: false,
              showPeopleAheadAfterCheckIn: true, // IF ORG HAS FALSE USE FALSE AND HIDE, ELSE TRUE AND SHOW
              showCheckInButton: true,
              showWaitingTime: true,
              showQRCode: false,
            }
          };

          $scope.images = {};
        }
      });
    });


  $scope.defaultAppointmentConfirmation = () => {
    if (!$scope.place.configuration.canConfirmAppointments || !$scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.requireAppointmentConfirmation = false;
    }
    if (!$scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.showWorkingDay = false;
    }
    if (!$scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.canProcessAppointment = false;
    }
    if ($scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.canConfirmAppointments = true;
    }
  };

  $scope.validateSpontaneousAndCheckinButton = prop => {
    if (prop === 'canConfirmSpontaneous') {
      if (!$scope.place.configuration.canConfirmSpontaneous && !$scope.place.mobileAppConfig.showCheckInButton) {
        $scope.place.mobileAppConfig.showCheckInButton = true;
        NotificationService.Information($translate.instant('set_showCheckInButton'), { timeOut: 10000 });
      }
    }
    if (prop === 'showCheckInButton') {
      if (!$scope.place.mobileAppConfig.showCheckInButton && !$scope.place.configuration.canConfirmSpontaneous) {
        $scope.place.configuration.canConfirmSpontaneous = true;
        NotificationService.Information($translate.instant('set_canConfirmSpontaneous'), { timeOut: 10000 });
      }
    }
  };

  $scope.newVideo = {
    src: '',
    poster: '',
  };

  $scope.markerImage = {
    url: '/img/common/dot-virtualine.png',
    size: [87, 87],
    scaledSize: [43, 43],
    origin: [0, 0],
    anchor: [15, 15]
  };

  $scope.timezones = {
    list: TimezoneService.List
  };

  $scope.languages = {
    list: {
      en: 'English',
      es: 'Español',
      pt: 'Portugués',
    }
  };

  // Validation

  function someIdentificationChecked() {
    const identifications = $scope.place.requirements.identifications
      .filter(id => isUndefined(id.empty))
      .filter(identification => identification.show);

    return identifications.length;
  }

  function noIdentificationChecked() {
    return !someIdentificationChecked();
  }

  function setAllIdentifications(value) {
    let identifications = $scope.place.requirements.identifications;

    if (identifications) {
      Object.keys(identifications).map(key => {
        identifications[key] = value;
        return key;
      });
    }
  }

  const validate = () => {
    const name = $scope.place.name;
    const address = $scope.place.info.address;
    const timezone = $scope.place.timezone;
    const language = $scope.place.language;
    const requirements = $scope.place.requirements;
    const checkInTolerance = $scope.place.configuration.checkInTolerance;
    const retained = $scope.place.configuration.retained;

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_place_name'));
      return false;
    }

    if (!language) {
      NotificationService.Warning($translate.instant('mandatory_lang_place'));
      return false;
    }

    if (!timezone) {
      NotificationService.Warning($translate.instant('mandatory_timezone_place'));
      return false;
    }

    if (!address) {
      NotificationService.Warning($translate.instant('mandatory_address_place'));
      return false;
    }

    if (requirements && requirements.id && noIdentificationChecked()) {
      NotificationService.Warning($translate.instant('mandatory_at_least_one_id'));
      return false;
    }

    if (requirements && requirements.id && !requirements.identifications.find(id => id.default)) {
      NotificationService.Warning($translate.instant('mandatory_at_least_one_default_id'));
      return false;
    }

    if (checkInTolerance && (checkInTolerance > 100 || checkInTolerance < 2) || checkInTolerance.toString().indexOf('.') === 1) {
      NotificationService.Warning($translate.instant('place_checkin_tolerance_range'));
      return false;
    }

    if (retained.enabled && (retained.days <= 0 || retained.days === undefined)) {
      NotificationService.Warning($translate.instant('retained_days_error'));
      return false;
    }

    return true;
  };


  $scope.onRequiredTypeIdChange = function () {
    $scope.place.requirements.id = someIdentificationChecked();
  };

  $scope.onRequiredIdChange = function (value) {
    if (value === false) {
      setAllIdentifications(false);
      $scope.place.configuration.aliasType = 'fullNameAlias';
      NotificationService.Information($translate.instant('alias_has_been_changed'));
    }
  };

  $scope.$watch('placeAddress.address', debounce(findByAddress, 500));

  function findByAddress() {
    // Minimum 6 characters to start finding address
    if ($scope.placeAddress.address && $scope.placeAddress.address.length > 5) {
      return LocationService.Find($scope.placeAddress.address)
        .then(result => {
          $timeout(() => {
            $scope.results = result;
          });
        });
    }
  }

  $scope.selectAddress = function (address) {
    $scope.place.info.address = address.address || address.label;
    $scope.place.googlePlaceId = address.googlePlaceId;
    $scope.place.location = address.location;
    $scope.place.locationAsString = [address.location.lat, address.location.lng];
    $scope.results = [];

    $timeout(async () => {
      const country = await getCountryByPlace($scope.place);

      $scope.place.location.country = country._id;
      $scope.place.location.countryCode = country.code;
      $scope.country = country.name;
      $scope.place.requirements.identifications = mergeCountryIdentifications(country.identifications, $scope.place.requirements.identifications);

      $scope.placeIdentifications = [...$scope.place.requirements.identifications];

      $scope.setDefaultIndentificationsList();
    });
  };

  function removePlanType() {
    if ($scope.place.enterpriseType !== 'whyline') {
      Reflect.deleteProperty($scope.place, 'plan');
    }
  }

  $scope.save = () => {
    if (validate()) {
      removePlanType();
      if (!$scope.place.branchId) {
        Reflect.deleteProperty($scope.place, 'branchId');
      }
      if (!$scope.place.checkInCode) {
        Reflect.deleteProperty($scope.place, 'checkInCode');
      }

      if ($scope.place.plan) {
        if ($scope.place.plan.name === '') {
          const type = $scope.place.plan.type;
          $scope.place.plan.name = type[0].toUpperCase() + type.slice(1);
        }
      }

      $scope.place.requirements.idType = $scope.place.requirements.id;

      $scope.place.requirements.identifications = $scope.place.requirements.identifications.filter(id => isUndefined(id.empty));

      if (placeId) {
        return PlaceService.Update($scope.place)
          .then(() => $scope.editPlaceMessages($scope.placeMessages))
          .then(() => NotificationService.Information('Cambios Guardados Correctamente!!'))
          .catch(ErrorService.handler);
      }
      return PlaceService.Create($scope.place)
        .then(response => {
          if ($scope.images) {
            return savePlaceImages(response._id, $scope.images);
          }
        })
        .catch(ErrorService.handler);
    }
  };

  function savePlaceImages(id, files) {
    NotificationService.Information($translate.instant('uploading_images'));
    return PlaceService.SaveImages(id, files)
      .then(() => NotificationService.Information($translate.instant('updated_images')))
      .catch(() => NotificationService.Error($translate.instant('err_update_image')));
  }

  $scope.uploadPlaceImage = (file, type) => {
    if (placeId) {
      updatePlaceImageByType(file, type);
    } else {
      $scope.images[type] = file;
    }
  };

  function updatePlaceImageByType(file, type) {
    if (file && type && $scope.place._id) {
      NotificationService.Information($translate.instant('uploading_image'));
      PlaceService.UpdateImageByType(file, type, $scope.place._id)
        .then(() => NotificationService.Information($translate.instant('updated_image')))
        .catch(() => NotificationService.Error($translate.instant('err_update_image')));
    } else {
      NotificationService.Warning($translate.instant('invalid_image_selected'));
    }
  }

  $scope.deleteImage = imageType => {
    if (placeId) {
      NotificationService.Information($translate.instant('deleting_image'));
      PlaceService.DeleteImageByType(placeId, imageType)
        .then(() => NotificationService.Information($translate.instant('deleted_image')))
        .catch(() => NotificationService.Error($translate.instant('err_deleting_image')));
    }
  };

  $scope.addVideo = () => {
    $scope.place.videos.push($scope.newVideo);
    $scope.newVideo = {
      src: '',
      poster: '',
    };
  };

  // VIDEOS
  $scope.deleteVideo = video => {
    dialog.confirm($translate.instant('video_remove_sure'))
      .then(() => {
        $scope.place.videos.pop(video);
        NotificationService.Information($translate.instant('video_remove_succ'));
      })
      .catch(ErrorService.handler);
  };

  $scope.editPlaceMessages = messages =>
    PlaceMessagesService.UpdateAllMessages(messages, placeId)
      .then(_messages => {
        $timeout(() => {
          $scope.placeMessages = _messages;
        });
      })
      .catch(ErrorService.handler);

  PlaceMessagesService.GetAllAsPromise(placeId)
    .then(messages => {
      $timeout(() => {
        $scope.placeMessages = messages;
      });
    })
    .catch(ErrorService.handler);
};

export default module.controller('PlacesEditController', PlacesEditController);
