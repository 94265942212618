import angular from 'angular';
import {
  concat,
  get
} from 'lodash';
import { localStorage } from '../utils';
import '../app.constants';
import '../services/place';
import '../services/totem';
import '../services/globals';
import '../services/line';
import '../services/totem-tv';
import '../services/errors';
import '../services/redirect-to-new-version';
import '../components/operator';
import '../components/status';
import '../directives/numbers-only';

const module = angular.module('whyline.controllers.totem-tv', [
  'whyline.constants',
  'whyline.services.place',
  'whyline.services.totem',
  'whyline.services.globals',
  'whyline.services.line',
  'whyline.services.totem-tv',
  'whyline.services.errors',
  'whyline.services.redirect-to-new-version',
  'whyline.components.operator',
  'whyline.components.status',
  'whyline.directives.numbersOnly',
]);

const TotemTvController = (SAFE_STATES, $scope, $window, PlaceService, $interval, $state, TotemTvService, $timeout, $translate, $rootScope, ErrorService, Fullscreen, RedirectToNewVersionService) => {
  /*@ngInject*/
  $rootScope.$broadcast('authenticated');

  // INIT values
  $scope.historyBtnForward = false;
  $scope.historyBtnBack = false;
  $scope.totem = {};
  $scope.currentPlaceId = '';
  $scope.showStatusView = false;
  $scope.showUserNumberView = false;
  $scope.showUserInfoView = false;
  $scope.person = TotemTvService.EmptyPerson();
  $scope.isLangEn = $translate.use() === 'en';
  $scope.isLangPt = $translate.use() === 'pt';
  $scope.showPopupInfo = false;
  $scope.closedMessage = '';
  let counter = 0;
  let wsTotem;

  if($state.current.name == SAFE_STATES.totemTvThanks) {
    $timeout(() => {
      $state.go(SAFE_STATES.totemTvWelcome);
    }, 5000);
  }

  // HISTORY FUNCTIONALITY
  // This is a function to go back/forward between views
  // managing the different states saved in a Stack array
  $scope.history = TotemTvService.History;

  PlaceService.GetOneAsPromise($state.params.placeId)
    .then(place => {
      $scope.place = place;
      if ($scope.place.revertMigration) {
        localStorage.get('user').then(user => {
          RedirectToNewVersionService.Redirect('totem', place._id, user._id, $state.params.totemId);
        });

      }
    })
    .catch(ErrorService.handler);

  PlaceService.GetOneAsPromise($state.params.placeId)
    .then(place => {
      $scope.place = place;
    })
    .catch(ErrorService.handler);

  // Update button status
  $rootScope.$on('$stateChangeSuccess', () => {
    $timeout(() => {
      $scope.historyBtnStatus = TotemTvService.GetButtonStatus();
      $scope.path = TotemTvService.GetPath();
      $scope.lastSelected = $scope.path[$state.params.n - 1];
      $scope.actualParams = $state.params;
      if ($state.current.name === SAFE_STATES.totemTvWelcome) {
        $scope.person = TotemTvService.EmptyPerson();
      }
    });
  });

  const createItemsProp = item => {
    let mergedArray = [];
    $timeout(() => {
      if (item.type === 'sector') {
        item.items = concat(mergedArray, item.schemasPopulated, item.areasPopulated, item.linesPopulated);
        return item;
      } else if (item.type === 'area') {
        item.items = item.linesPopulated;
        return item;
      }
    });
  };

  //
  // SELECT OPTION
  $scope.select = selected => {
    const customMessage = get($scope, 'place.configuration.closedMessage', false);
    if(selected.type !== 'sector' && !selected.currentStatus.isOpenNow) {
      if(customMessage && customMessage.length > 0) {
        $scope.closedMessage = customMessage;
        $scope.showPopupInfo = true;
      }
      return false;
    } else if(selected.type === 'sector' || selected.type === 'area' && selected.ordered === false) {
      createItemsProp(selected);
      TotemTvService.Select(selected);
      $timeout(() => {
        $scope.totem = TotemTvService.GetCurrent();
      });
    } else {
      TotemTvService.Select(selected);
      $state.go(SAFE_STATES.totemTvStatus);
    }
  };

  $scope.closePopup = () => {
    $scope.showPopupInfo = false;
  };

  $scope.pressForFullScreen = () => {
    if(counter < 3) {
      counter += 1;
    }
    if(counter === 3) {
      counter = 0;
      if(Fullscreen.isEnabled()) {
        Fullscreen.cancel();
      } else {
        Fullscreen.all();
      }
    }
  };

  $rootScope.$on('totem:identify', (event, data) => {
    $scope.identify = true;
    $interval(() => $scope.identify = false, 5000);
  });

  $rootScope.$on('totem:refresh', (event, data) => {
    $window.location.reload();
  });
};

module.exports = module.controller('TotemTvController', TotemTvController);
