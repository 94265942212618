import angular from 'angular';
import '../../services/organization';
import '../../services/category';
import '../../services/user';
import '../../services/auth';
import '../../services/plan';
import '../../services/timezone';
import '../../services/notifications';
import '../../services/organization-messages';
import '../../services/errors';
import { cloneDeep, get, isNil } from 'lodash';
import { localStorage } from '../../utils';

const module = angular.module('whyline.controllers.organizations.create', [
  'whyline.services.organization',
  'whyline.services.category',
  'whyline.services.auth',
  'whyline.services.user',
  'whyline.services.plan',
  'whyline.services.timezone',
  'whyline.services.notifications',
  'whyline.services.errors',
]);

const OrganizationsCreateController = ($scope, $state, $timeout, $translate, SAFE_STATES, Slug, dialog, OrganizationService, CategoryService, TimezoneService, NotificationService, PlanService, UserService, AuthService, ErrorService, OrganizationMessagesService) => {
  /*@ngInject*/

  //
  // Init controller
  $scope.timezones = { list: TimezoneService.List };
  $scope.languages = { list: { en: 'English', es: 'Español', pt: 'Portugués' } };
  $scope.repeatedPassword = '';
  $scope.userExists = true;
  $scope.validEmail = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,5})$/;
  $scope.urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
  $scope.canComplete = true;
  $scope.selectedPlan = {};
  $scope.emailValidationMsg = 'org_complete_user_by_email';

  localStorage.get('user').then(user => {
    $scope.superAdmin = user && user.sudo;
  });

  $scope.messages = OrganizationMessagesService.GetDefaults();

  PlanService.GetAllActive()
    .then(plans => {
      $timeout(() => {
        $scope.categories = [];
        $scope.plans = plans;
        $scope.tooltips = {
          checkInEarly: 'tooltip_check_in_early',
          numberPeopleAhead: 'tooltip_people_ahead',
          youAreNext: 'tooltip_you_are_next',
          checkIn: 'tooltip_check_in',
          itsYourTurn: 'tooltip_its_your_turn',
        };
        $scope.organization = {
          name: '',
          slug: '',
          timezone: '',
          language: $translate.use(),
          website: '',
          phoneNumber: '',
          enabledForMobileApp: false,
          externalId: '',
          hiddenInMobileApp: false,
          configuration: {
            appointmentReminder: {
              enabled: false,
            },
            checkIn: {
              min: 0,
              max: 0,
            },
            mobileApp: {
              showPeopleAheadAfterCheckIn: true,
              canSendAppointmentDelayAlert: false,
              showWaitingTime: false,
              showQRCode: false,
            },
            requirements: {
              id: false,
              virtualine: {
                email: false,
              },
            },
            plan: {},
            queuer: {
              enterpriseType: 'ba', // TBR when filacero gets implemented.
              qr: '',
              hasEnterprise: true,
              aliasType: 'fullNameAlias',
              boxLabelRequired: false,
              canConfirmAppointments: false,
              checkInTolerance: 2,
              closedMessage: '',
              canEditAppointments: true,
              requireAppointmentConfirmation: false,
              canProcessAppointment: false,
              canGiveAppointments: false,
              canCloneAndForward: false,
              canSkipProcessOrder: false,
              requeue: {
                enabled: false,
                limit: {
                  in: {
                    minutes: 60,
                  },
                },
              },
              retained: {
                enabled: false,
                days: 90
              },
              showWaitingInLineInPlace: true,
              showWorkingDay: false,
            },
            reasonRequired: false,
            reasons: [],
            requestReasonAndMotive: false,
            thirdPartyInfo: {
              getSubscriptionInfo: false
            },
          },
          categories: [],
        };
        // Set initial plan 'Enterprise' as default.
        const planKey = Object.keys($scope.plans).find(key => $scope.plans[key].type === 'enterprise');
        $scope.selectedPlan = $scope.plans[planKey];
        $scope.changeIdToPlanId($scope.plans[planKey]);
      });
    })
    .catch(ErrorService.handler);

  // Validations
  const validate = () => {
    const name = $scope.organization.name;
    const slug = $scope.organization.slug;
    const timezone = $scope.organization.timezone;
    const language = $scope.organization.language;
    const categoriesSelected = $scope.organization.categories;
    const qrCode = $scope.organization.configuration.queuer.qr;
    const plan = $scope.organization.configuration.plan;
    const website = $scope.organization.website;
    const checkInTolerance = $scope.organization.configuration.queuer.checkInTolerance;
    const phoneNumber = $scope.organization.phoneNumber;
    const checkInMin = get($scope, 'organization.configuration.checkIn.min', null);
    const checkInMax = get($scope, 'organization.configuration.checkIn.max', null);

    if (!$scope.validEmail.test(email)) {
      NotificationService.Warning($translate.instant('add_valid_email'));
      return false;
    }

    if (!firstName || !lastName) {
      NotificationService.Warning($translate.instant('mandatory_firstName_and_lastName'));
      return false;
    }

    if (!$scope.userExists && (password && password.length) && (password !== $scope.repeatedPassword)) {
      NotificationService.Warning($translate.instant('pw_must_match'));
      return false;
    }

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_name'));
      return false;
    }

    if (name.length > 100) {
      NotificationService.Warning($translate.instant('organization_name_too_long'));
      return false;
    }

    if (!slug) {
      NotificationService.Warning($translate.instant('mandatory_alias'));
      return false;
    }

    if (!language) {
      NotificationService.Warning($translate.instant('mandatory_language'));
      return false;
    }

    if (!timezone) {
      NotificationService.Warning($translate.instant('mandatory_timezone_place'));
      return false;
    }

    if (phoneNumber && !(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/).test(phoneNumber)) {
      NotificationService.Warning($translate.instant('invalid_tel'));
      return false;
    }

    if (!qrCode) {
      $scope.organization.configuration.queuer.qr = `${Slug.slugify($scope.organization.name)}_check_in`;
    }

    if (checkInTolerance && (checkInTolerance > 100 || checkInTolerance < 2) || checkInTolerance.toString().indexOf('.') === 1) {
      NotificationService.Warning($translate.instant('organization_checkin_tolerance_range'));
      return false;
    }

    if (typeof website === 'undefined' && $scope.organizationCreate.organizationWebsite.$dirty && $scope.organizationCreate.organizationWebsite.$error) {
      NotificationService.Warning($translate.instant('url regex'));
      return false;
    }

    if (!plan || !plan.planId) {
      NotificationService.Warning($translate.instant('mandatory_plan'));
      return false;
    }

    const messagesCondition = $scope.messages.find(organizationMessage => organizationMessage.messages.find(message => !message));
    if (messagesCondition) {
      NotificationService.Warning($translate.instant('messages_required'));
      return false;
    }

    if ($scope.organization.configuration && !validateMotiveReason($scope.organization.configuration)) {
      return false;
    }

    if (isNil(checkInMin) || (checkInMin < 1 || checkInMin > 10000)) {
      NotificationService.Warning($translate.instant('error_pre_check_in_invalid_range'));
      return false;
    }

    if (isNil(checkInMax) || (checkInMax < 1 || checkInMax > 10000)) {
      NotificationService.Warning($translate.instant('error_post_check_in_invalid_range'));
      return false;
    }

    return true;
  };

  $scope.setMinorThanZero = (value, property) => {
    if (value < -1 && $scope.organization.configuration.plan.hasOwnProperty(property)) {
      $scope.organization.configuration.plan[property] = -1;
    }
  };

  $scope.changeIdToPlanId = plan => {
    if (!plan) {
      return;
    }

    $scope.organization.configuration.plan = {
      ...plan,
      planId: plan._id,
    };

    Reflect.deleteProperty($scope.organization.configuration.plan, '_id');
  };

  // Save
  $scope.save = () => {
    if (validate()) {
      const orgToSend = cloneDeep($scope.organization);
      OrganizationService.Create(orgToSend)
        .then(organization => {
          $timeout(() => {
            OrganizationMessagesService.UpdateAllMessages($scope.messages, organization._id.toString(), false);

            NotificationService.Success($translate.instant('organization_create_succ'));
            $state.go(SAFE_STATES.organizationsList);
          });
        })
        .catch(ErrorService.handler);
    }
  };

  $scope.addReason = () => {
    $scope.organization.configuration.reasons.unshift({
      name: '',
    });
  };

  $scope.deleteReason = index => {
    dialog.confirm($translate.instant('reason_remove_sure'))
      .then(() => {
        $scope.organization.configuration.reasons.splice(index, 1);
        NotificationService.Information($translate.instant('reason_remove_succ'));
      })
      .catch(ErrorService.handler);
  };

  $scope.defaultAppointmentConfirmation = () => {
    if (!$scope.organization.configuration.queuer.canGiveAppointments || !$scope.organization.configuration.queuer.canConfirmAppointments) {
      $scope.organization.configuration.queuer.requireAppointmentConfirmation = false;
    }
    if (!$scope.organization.configuration.queuer.canGiveAppointments) {
      $scope.organization.configuration.queuer.showWorkingDay = false;
    }
    if (!$scope.organization.configuration.queuer.canGiveAppointments) {
      $scope.organization.configuration.queuer.canProcessAppointment = false;
    }
    if ($scope.organization.configuration.queuer.canGiveAppointments) {
      $scope.organization.configuration.queuer.canConfirmAppointments = true;
    }
  };

  $scope.requestReasonAndMotiveChange = value => {
    if (!value) {
      $scope.organization.configuration.reasonRequired = false;
    }
  };

  const validateMotiveReason = configuration => {
    const arrayWithoutDuplicates = [...new Set(configuration.reasons.map((r) => r.name))];
    if (arrayWithoutDuplicates.length !== configuration.reasons.length) {
      NotificationService.Warning($translate.instant('duplicated-reasons'));
      return false;
    }
    for (const reason of configuration.reasons) {
      if (!reason.name) {
        NotificationService.Warning($translate.instant('reason and motive empty'));
        return false;
      }
    }
    return true;
  };
};

export default module.controller('OrganizationsCreateController', OrganizationsCreateController);
