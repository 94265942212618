import angular from 'angular';
import '../../app.constants';
import Cache from './cache';
import Collection from './collection';
import Instance from './instance';

const module = angular.module('whyline.resources.resource', [
  'whyline.constants'
]);

const ResourceFactory = (API_URL, $q, $http, $timeout) => {
  /*@ngInject*/

  Collection.$inject('API_URL', API_URL);
  Collection.$inject('$q', $q);
  Collection.$inject('$http', $http);
  Collection.$inject('$timeout', $timeout);

  Instance.$inject('API_URL', API_URL);
  Instance.$inject('$http', $http);
  Instance.$inject('$timeout', $timeout);

  /**
   * Constructor
   *
   * @param {string} type
   * @param {Object} schema
   * @param {Object} ChildClass
   * @return {Object <Resource>}
   * @public
   */
  const Resource = function(type, schema, ChildClass) {
    this._type = type;

    this._schema = schema;

    this._ChildClass = ChildClass;

    this._populated = [];

    this._populate = Instance.Populate;
  };

  /**
   * Empties the whole cache
   *
   * @return {Object}
   * @public
   * @static
   * @final
   */
  Resource.RestartCache = Cache.Restart;

  /**
   * Extends child class with static methods
   *
   * @param {string} type The resource type for /api/v1/${type}...
   * @param {Class} ChildClass The child class constructor
   * @public
   * @static
   */
  Resource.Extend = (type, ChildClass) => {
    //Instance
    ChildClass.Clean = item => Instance.Clean(item);

    ChildClass.Save = (item, options) => Instance.Save(item, options);

    ChildClass.Remove = item => Instance.Remove(item);

    ChildClass.Populate = (item, fields) => Instance.Populate(item, fields);

    ChildClass.Depopulate = (item, fields) => Instance.Depopulate(item, fields);

    //Cache Admin
    ChildClass.InitCache = validityTime => Cache.Init(validityTime, type);

    ChildClass.TruncateCache = () => Cache.Truncate(type);

    ChildClass.InvalidateCache = () => Cache.Invalidate(type);

    ChildClass.IsCacheValid = () => Cache.IsValid(type);

    ChildClass.UpdateCache = (id, item, options) => Cache.Update(id, item, options, type, ChildClass);

    ChildClass.UpdateCacheArray = (list, options) => Cache.UpdateArray(list, options, type, ChildClass);

    ChildClass.UpdateCacheSearch = (url, list, options) => Cache.UpdateSearch(url, list, options, type, ChildClass);

    ChildClass.ClearCacheSearch = url => Cache.ClearSearch(url, type);

    ChildClass.ClearCacheData = url => Cache.ClearData(url, type);

    ChildClass.ReserveCache = id => Cache.Reserve(id, type, ChildClass);

    ChildClass.RemoveFromCache = id => Cache.RemoveFrom(id, type);



    //Query Cache
    ChildClass.GetCacheSearch = url => Cache.GetSearch(url, type);

    ChildClass.SearchCacheExists = url => Cache.SearchExists(url, type);

    ChildClass.GetByIdFromCache = id => Cache.GetByIdFrom(id, type);

    ChildClass.GetAllFromCache = () => Cache.GetAllFrom(type);

    ChildClass.GetFromCache = (prop, value) => Cache.GetFrom(prop, value, type);

    ChildClass.GetOneFromCache = (prop, value) => Cache.GetOneFrom(prop, value, type);

    //Query Server
    ChildClass.FetchAll = options => Collection.FetchAll(options, type, ChildClass);

    ChildClass.FindBy = (prop, value, options) => Collection.FindBy(prop, value, options, type, ChildClass);

    ChildClass.FindOneBy = (prop, value, options) => Collection.FindOneBy(prop, value, options, type, ChildClass);

    ChildClass.FindById = (id, options) => Collection.FindById(id, options, type, ChildClass);

    ChildClass.FindByIds = (ids, options) => Collection.FindByIds(ids, options, type, ChildClass);
  };

  return Resource;
};

module.exports = module.factory('Resource', ResourceFactory);
