import {
  localStorage
} from './utils';
import {
  get,
  includes,
} from 'lodash';
import './app.constants';

const addOrganizationIdAndPlaceId = ($state, evt, toState, toParams, GlobalsService, SAFE_STATES) => {
  const organization = GlobalsService.CurrentOrganization;
  const place = GlobalsService.CurrentPlace;

  const newParams = {
    ...toParams
  };

  const originalToState = toState;

  let executeGo = false;

  if (!newParams.organizationId) {
    if (organization && organization._id) {
      //Adds organizationId query params if necessary

      newParams.organizationId = organization._id;
      executeGo = true;
    } else {
      //Redirects to organization selection page
      return $state.go(SAFE_STATES.organizationsList);
    }
  } else if (newParams.organizationId && !(organization && organization._id)) {
    //Reads organizationId from url on reload
    GlobalsService.SetCurrentOrganization({
      _id: newParams.organizationId
    });
  }

  if (toState.url.indexOf('/organization/') === -1) {
    if (!newParams.placeId) {
      if (place && place._id) {
        //Adds placeId query params if necessary
        newParams.placeId = place._id;

        executeGo = true;
      } else if (newParams.organizationId) {
        // @TODO : ver porque estaba este codigo
        //Redirects to place selection page
        // executeGo = true;
        // toState = SAFE_STATES.placesList;
      }
    } else if (newParams.placeId && !(place && place._id)) {
      //Reads placeId from url on reload
      GlobalsService.SetCurrentPlace({
        _id: toParams.placeId
      });
    }
  }

  if (executeGo) {
    if (originalToState.name.indexOf(toState.name || toState) == -1) {
      evt.preventDefault();
    }

    setTimeout(function () {
      $state.go(toState, newParams);
    });
  }
};

const setUpByUrl = ($location, GlobalsService, $rootScope, $window, UserService) => {
  // set the branding to the current enteprise session
  const url = $location.absUrl();
  if (includes(url, 'filacero')) {
    return GlobalsService.SetEnterpriseType('ba');
  } else {
    // Chat support
    $rootScope.$on('authenticated', (event, user) => {
      if (user && user.hasOwnProperty('local') && user.local.email) {
        window.fcWidget.init({
          token: '4efb855c-bbd6-4ecf-bb35-1db8440e10f7',
          host: 'https://wchat.freshchat.com',
          siteId: 'Virtualine',
          externalId: user._id,
          restoreId: user.externalSupportId ? user.externalSupportId : null,
          config: {
            agent: {
              hideName: false,
              hidePic: false,
              hideBio: false,
            },
          }
        });

        window.fcWidget.user.get(response => {
          let status = response && response.status;
          let data = response && response.data;

          if (status !== 200) {
            window.fcWidget.user.setProperties({
              firstName: `${user.profile.firstName}`,
              lastName: `${user.profile.lastName}`,
              email: user.local.email,
              phone: user.profile.hasOwnProperty('phoneNumber') ? user.profile.phoneNumber : null,
              createdAt: user.hasOwnProperty('createdAt') ? Math.floor((+new Date(user.createdAt)) / 1000) : null,
              // locale: LanguageService.GetBrowserLanguage(),
            });

            window.fcWidget.on('user:created', response => {
              let status = response && response.status;
              let data = response && response.data;
              if (status === 200) {
                if (data.restoreId) {
                  UserService.setExternalSupportId(data.restoreId);
                }
              }
            });
          }
        });
      }
    });

    $rootScope.$on('unauthenticated', () => {
      window.fcWidget.destroy();
    });

    return GlobalsService.SetEnterpriseType('whyline');
  }
};

/**
 * Executes before route changing
 */
const stateChangeStart = ($state, evt, toState, toParams, GlobalsService, SAFE_STATES, $location, $rootScope, $window, PlaceService, UserService) => {
  //saves the last user interaction whenever the state change
  localStorage.set('lastUpdate', Date.now());

  // Get and set the enterprise type (for branding)
  setUpByUrl($location, GlobalsService, $rootScope, $window, UserService);

  const statesWithOrganizationId = [
    SAFE_STATES.boxes,
    SAFE_STATES.organizationAreas,
    SAFE_STATES.placesList,
    SAFE_STATES.sectors,
    SAFE_STATES.organizationLines,
    SAFE_STATES.organizationCustomRole,
    SAFE_STATES.organizationUsers,
    SAFE_STATES.nowServing,
    SAFE_STATES.nowServingQueue,
    SAFE_STATES.reception,
    SAFE_STATES.receptionStep,
    SAFE_STATES.receptionStatus,
    SAFE_STATES.receptionWelcome,
    SAFE_STATES.receptionAppointmentSearch,
    SAFE_STATES.receptionAppointmentResults,
    SAFE_STATES.receptionAppointmentConfirm,
    SAFE_STATES.displays,
    SAFE_STATES.lines,
    SAFE_STATES.areas,
    SAFE_STATES.totems,
    SAFE_STATES.devices,
    SAFE_STATES.schemas,
    SAFE_STATES.users,
    SAFE_STATES.roles,
    SAFE_STATES.resourceGroups,
    SAFE_STATES.qrGenerator,
    SAFE_STATES.videos,
    SAFE_STATES.schedule,
    SAFE_STATES.scheduleManagement,
    SAFE_STATES.scheduleHolidays,
    SAFE_STATES.scheduleAppointmentFilters,
    SAFE_STATES.scheduleAppointmentResults,
    SAFE_STATES.scheduleAppointmentConfirm,
    SAFE_STATES.placeSectors,
    SAFE_STATES.organizationCallLogics,
    SAFE_STATES.banners,
    SAFE_STATES.organizationConditions,
    SAFE_STATES.organizationScenarios,
    SAFE_STATES.scenarios,
  ];

  //check if place is selected
  const statesWithPlaceId = [
    SAFE_STATES.boxes,
    SAFE_STATES.nowServing,
    SAFE_STATES.nowServingQueue,
    SAFE_STATES.reception,
    SAFE_STATES.receptionStep,
    SAFE_STATES.receptionStatus,
    SAFE_STATES.receptionWelcome,
    SAFE_STATES.receptionAppointmentSearch,
    SAFE_STATES.receptionAppointmentResults,
    SAFE_STATES.receptionAppointmentConfirm,
    SAFE_STATES.displays,
    SAFE_STATES.lines,
    SAFE_STATES.areas,
    SAFE_STATES.totems,
    SAFE_STATES.devices,
    SAFE_STATES.schemas,
    SAFE_STATES.users,
    SAFE_STATES.roles,
    SAFE_STATES.resourceGroups,
    SAFE_STATES.displayTv,
    SAFE_STATES.totemTv,
    SAFE_STATES.totemTvWelcome,
    SAFE_STATES.totemTvStep,
    SAFE_STATES.totemTvStatus,
    SAFE_STATES.totemTvUser,
    //  SAFE_STATES.stats,
    SAFE_STATES.qrGenerator,
    SAFE_STATES.videos,
    SAFE_STATES.placeSectors,
    SAFE_STATES.placeCallLogics,
    SAFE_STATES.banners,
    SAFE_STATES.scenarios,
  ];


  if (statesWithOrganizationId.indexOf(toState.name) >= 0 || statesWithPlaceId.indexOf(toState.name) >= 0) {
    addOrganizationIdAndPlaceId($state, evt, toState, toParams, GlobalsService, SAFE_STATES);
  } else if (toState.name === SAFE_STATES.placesList) {
    //Cleans the current place from global service
    GlobalsService.SetCurrentPlace();
  }
};

/*
 * Checks the users have permissions to enter a section
 */
const checkPermissions = ($state, evt, toState, toParams, AccessControlService, NotificationService, SAFE_STATES, GlobalsService) => {
  if (toParams.hasOwnProperty('organizationId') && toParams.organizationId && toParams.hasOwnProperty('permission') && toParams.permission) {
    if (AccessControlService.isDisabledForOrganization(toParams.organizationId)) {
      NotificationService.Error('No tienes permisos para acceder a esta sección.');
      GlobalsService.SetCurrentOrganization();
      GlobalsService.SetCurrentPlace();
      return $state.go('app.organizations.list', {});
    }
  }
  if (toParams.hasOwnProperty('placeId') && toParams.placeId && toParams.hasOwnProperty('permission') && toParams.permission) {
    if (AccessControlService.isDisabledForPlace(toParams.placeId)) {
      NotificationService.Error('No tienes permisos para acceder a esta sección.');
      GlobalsService.SetCurrentPlace();
      return $state.go(SAFE_STATES.placesList, {});
    }
    AccessControlService.CanPerformAction([toParams.permission], toParams.placeId)
      .then(response => {
        if (!response) {
          NotificationService.Error('No tienes permisos para acceder a esta sección.');
          GlobalsService.SetCurrentPlace();
          $state.go(SAFE_STATES.placesList, {});
        }
      });
  }
};

const configNavBarLeft = (localStorage, $rootScope, $window) => {
  // Check the current device width
  const windowWidthMd = $window.innerWidth < 992;
  // Restore locked or floating navBarLeft
  let lockNavBarLeft = localStorage.get('lockNavBarLeft');
  // If lockNavBarLeft was never set, set it as locked
  if (lockNavBarLeft === undefined) {
    localStorage.set('lockNavBarLeft', true);
    lockNavBarLeft = true;
  }
  // Unlock navBarLeft
  if (windowWidthMd) {
    localStorage.set('lockNavBarLeft', false);
    lockNavBarLeft = false;
    $rootScope.hideLockNavBarSmallDevices = windowWidthMd;
  }
  $rootScope.lockNavBarLeft = lockNavBarLeft;
  // Set navBarLeft as floating and collapsed if its unlocked
  $rootScope.navBarLeftFloat = !$rootScope.lockNavBarLeft || windowWidthMd;
  $rootScope.navBarLeftCollapsed = !$rootScope.lockNavBarLeft || windowWidthMd;
};

const run = ($document, $window, $location, $rootScope, $state, authManager, GlobalsService, AuthService, NotificationService, SAFE_STATES, $translate, AccessControlService, $timeout, PlaceService, $breadcrumb, UserService) => {
  'ngInject';

  configNavBarLeft(localStorage, $rootScope, $window);

  $rootScope.$state = $state;
  $window.ga('create', 'UA-81824087-2', 'auto');

  $document.on('visibilitychange', function () {
    $rootScope.$broadcast('visibilityChange', document.hidden, document.visibilityState);
  });

  $rootScope.$on('$stateChangeStart', (evt, toState, toParams) => {
    // Redirect attr in routes config
    if (toState.redirectTo) {
      evt.preventDefault();
      return $state.go(toState.redirectTo, toParams, { location: 'replace' });
    }

    // If it is not a redirect, start state change
    stateChangeStart($state, evt, toState, toParams, GlobalsService, SAFE_STATES, $location, $rootScope, $window, PlaceService, UserService);
    // Check if user have the correct permissions to access the section
    checkPermissions($state, evt, toState, toParams, AccessControlService, NotificationService, SAFE_STATES, GlobalsService);
  });
};

module.exports = run;
