import angular from 'angular';
import './resource';
import '../app.constants';
import '../resources/subscription';
import { localStorage, schedule } from '../utils';
import { isEmpty, values } from 'lodash';

const module = angular.module('whyline.resources.line', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.resources.subscription',
]);

const LineFactory = (API_URL, $http, Resource, $timeout, Subscription) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'line?placeId';

  const _schema = {
    _id: {
      type: String,
    },
    placeId: {
      type: String,
      required: true,
    },
    areaId: {
      type: String,
      required: true,
    },
    supervisorLine: {
      type: Boolean,
      required: true,
    },
    isDeleted: {
      type: Boolean,
    },
    isWithoutAppointment: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    averageWaitingByNumber: {
      type: Number,
      required: true,
    },
    lastNumberCalled: {
      type: Number,
      required: true,
    },
    lastNumberGiven: {
      type: Number,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
      default: schedule.create(),
    },
    maxNumbers: {
      type: Number,
      required: true,
      default: 0,
    },
    lastCallTime: {
      type: Date,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    boxLabel: {
      type: String,
      required: false,
    },
    defaultLineFlowTypeOrganization: {
      type: String,
    },
    configuration: {
      messages: {
        preJoin: {
          type: String,
          default: '',
        },
        postJoin: {
          type: String,
          default: '',
        },
        postCheckIn: {
          type: String,
          default: '',
        },
      },
      showSubscriptionsBy: {
        dailySubscriptions: {
          type: Boolean,
          default: true,
        },
        lastHoursSubscriptions: {
          type: Boolean,
          default: false,
        },
      },
    },
    sla: {
      default: 0,
      type: Number,
    },
    requestSource: {
      reception: Boolean,
      totem: Boolean,
      mobileApp: Boolean,
    },
    checkAppointments: {
      type: Boolean,
      default: false,
      required: false
    }
  };

  const Line = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.clear = () => {
      const url = `${API_URL}${_type}/${this._id}/clear`;
      return $http.post(url).then((response) => response);
    };

    this.enable = (enabled) => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });
      promise.then((response) => {
        const item = response.data;
        $timeout(() => {
          // Cuando halla respuesta, modificar el cache
          Line.UpdateCache(item._id, item, null);
        });
        // Devuelve la respuesta desde el cache.
        return Line.GetOneFromCache('_id', this._id);
      });
      return promise;
    };
  };

  Line.GetAreaPreference = (lineId, placeId) => {
    const url = `${API_URL}line/${lineId}/place/${placeId}/preference`;
    return $http.get(url).then((response) => Promise.resolve(response));
  };

  Line.CallNext = (lineId) => {
    const selectedBox = localStorage.get('selectedBox', null);
    let boxName = selectedBox ? selectedBox.name : selectedBox;
    const url = `${API_URL}line/${lineId}/call-next?placeId`;
    return $http
      .post(url, {
        selectedBox: boxName,
      })
      .then((response) => {
        const subscription = response.data;
        Subscription.UpdateCache(subscription._id, subscription);
        return Promise.resolve(subscription);
      });
  };

  Line.CallTo = (subscriptionId, lineId) => {
    const selectedBox = localStorage.get('selectedBox', null);
    let boxName = selectedBox ? selectedBox.name : selectedBox;
    const url = `${API_URL}subscription/${subscriptionId}/call-to?lineId=${lineId}?placeId`;
    return $http
      .post(url, {
        selectedBox: boxName,
      })
      .then((response) => {
        const subscription = response.data;
        Subscription.UpdateCache(subscription._id, subscription);
        return Promise.resolve(subscription);
      });
  };

  Line.GetMyLines = () => {
    const selectedBox = localStorage.get('selectedBox');
    const url = selectedBox ? `${API_URL}${_type}?boxId=${selectedBox._id}` : `${API_URL}${_type}`;
    const cacheUrl = selectedBox
      ? `${API_URL}line/allowed?boxId${selectedBox._id}`
      : `${API_URL}line/allowed`;
    const cacheExists = Line.SearchCacheExists(cacheUrl);
    const list = Line.GetCacheSearch(cacheUrl);
    if (isEmpty(list) && !cacheExists) {
      return $http.get(url).then((response) => {
        if (response.data) {
          Line.UpdateCacheSearch(cacheUrl, values(response.data), {});
        }
        return Line.GetCacheSearch(cacheUrl);
      });
    }
    return list;
  };

  Line.prototype = new Resource(_type, _schema, Line);

  Resource.Extend(_type, Line);

  //five minutes
  const validityTime = 5 * 60 * 1000;
  Line.InitCache(validityTime);

  return Line;
};

module.exports = module.factory('Line', LineFactory);
