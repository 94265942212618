import angular from 'angular';
import '../services/banner';
import '../services/errors';
import '../services/notifications';
import './modals/banner';
import '../services/globals';

const module = angular.module('whyline.controllers.banners', []);

const BannersController = function () {
};

module.exports = module.controller('BannersController', BannersController);
