import angular from 'angular';
import '../../components/osom';
import '../../components/queue';
import '../../components/permission';
import '../../services/place';
import '../../services/line';
import '../../services/area';
import '../../services/user';
import '../../services/resource-group';
import '../../services/auth';
import '../../services/errors';
import { filter, forEach, indexOf, remove, set, sortBy } from 'lodash';

const module = angular.module('whyline.modals.user', [
  'whyline.components.osom',
  'whyline.components.queue',
  'whyline.components.permission',
  'whyline.services.place',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.user',
  'whyline.services.resource-group',
  'whyline.services.auth',
  'whyline.services.errors',
]);

const UserModalController = function (
  $uibModalInstance,
  $translate,
  NotificationService,
  UserService,
  AreaService,
  LineService,
  ErrorService,
  userId,
  roles,
  placeId,
  $timeout,
  AuthService,
  ResourceGroupService,
  PlaceService,
  dialog,
) {
  'ngInject';

  // Initialization
  this.newPw = {
    newPassword: '',
    newConfirmPassword: '',
  };
  this.showChangePassword = false;
  this.currentUser = UserService.GetCurrent();
  this.placeId = placeId;
  this.globalUserPermission = '';
  this.alreadyShown = {};
  this.user = {
    profile: {},
    local: {},
    permissions: [],
    externalId: '',
  };
  const allRoles = ['SiGeCi', 'Whyline - SuperUser', 'Supervisor', 'Organization Admin', 'operator', 'Admin General', 'Coordinator', 'Receptionist', 'Base Receptionist', 'Statistical', 'Operator Full', 'Operator Base'];
  const keys = Object.keys(roles);
  const rolesToFiler = [];
  for (const key of keys) {
    rolesToFiler.push(roles[key]);
  }
  this.roles = sortBy(rolesToFiler.filter(r => allRoles.indexOf(r.name) > -1), 'name');
  this.customRoles = sortBy(rolesToFiler.filter(r => allRoles.indexOf(r.name) < 0), 'name');
  this.customRoleSelected = (this.globalUserPermission == '') ? this.customRoles[0] : {};
  this.placeResource = {};
  this.boxLabelRequired = PlaceService.GetCurrent().configuration.boxLabelRequired;
  if (userId) {
    UserService.GetOneAsPromiseFromServer(userId)
      .then(user => {
        user.permissions.forEach(permission => {
          if (allRoles.indexOf(permission.role.name) < 0) {
            const selected = this.customRoles.find(cr => cr._id === permission.role._id);
            this.customRoleSelected = selected;
            permission.role = {name: 'other'};
          }
        });
        $timeout(() => {
          this.user = UserService.Copy(user);
          this.globalUserPermission = UserService.GetCurrentRoleFor(user);
          generateAlreadyShown();
        });
      })
      .catch(ErrorService.handler);
  }
  let validate = () => {
    const firstName = this.user.profile.firstName;
    const lastName = this.user.profile.lastName;
    const email = this.user.local.email;
    const password = this.newPw.newPassword;
    const confirmPassword = this.newPw.newConfirmPassword;
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const stringPattern = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ .-]+$/;

    if (!firstName) {
      NotificationService.Warning($translate.instant('mandatory_user_name'));
      return false;
    }

    if (firstName.length < 2 || firstName.length > 512) {
      NotificationService.Warning($translate.instant('user_firstname_length'));
      return false;
    }

    if (firstName && !stringPattern.test(firstName)) {
      NotificationService.Warning($translate.instant('invalid_name_format'));
      return false;
    }

    if (!lastName) {
      NotificationService.Warning($translate.instant('mandatory_user_lastname'));
      return false;
    }

    if (lastName.length < 2 || lastName.length > 512) {
      NotificationService.Warning($translate.instant('user_lastname_length'));
      return false;
    }

    if (lastName && !stringPattern.test(lastName)) {
      NotificationService.Warning($translate.instant('invalid_surname_format'));
      return false;
    }

    if (!email) {
      NotificationService.Warning($translate.instant('mandatory_user_mail'));
      return false;
    }

    if (email && !emailPattern.test(email)) {
      NotificationService.Warning($translate.instant('invalid_email_format'));
      return false;
    }

    if (!this.globalUserPermission) {
      NotificationService.Warning($translate.instant('mandatory_global_permission'));
      return false;
    }

    if (this.globalUserPermission.name === 'operator' && this.user.permissions.length === 0) {
      NotificationService.Warning($translate.instant('mandatory_line_or_process_operator'));
      return false;
    }

    if (password !== confirmPassword) {
      NotificationService.Warning($translate.instant('pw_must_match'));
      return false;
    }

    if ((password || confirmPassword) & (password.length < 5 || confirmPassword.length < 5)) {
      NotificationService.Warning($translate.instant('pw_min_length'));
      return false;
    }

    if (this.boxLabelRequired) {
      const name = this.queue? this.queue.label: this.label;

      const msjValidations = this.user.permissions.filter(permission => 
        permission.boxLabelEnabled && permission.boxLabelVisible && (!permission.boxLabel || !permission.boxLabel.trim())
      ).map((permission) =>  
        $translate.instant('{name}_box_label_required').replace('{name}', permission.resourceGroup.name.toUpperCase())
      );
      
      if(msjValidations && msjValidations.length > 0) {
        msjValidations.forEach(msj => NotificationService.Warning(msj));
        return false;
      }
    }

    return true;
  };


  this.save = () => {
    if (validate()) {
      if (this.newPw.newPassword) {
        set(this, 'user.local.password', AuthService.Encrypt(this.newPw.newPassword));
      } else {
        set(this, 'user.local.password', null);
      }
      this.checkPlacePermission()
        .then(() => {
          const otherPermission = this.user.permissions.find(p => p.role && p.role.name && p.role.name === 'other');
          if (otherPermission) {
            this.user.permissions[this.user.permissions.indexOf(otherPermission)] = {
              role: {
                actions: this.customRoleSelected.actions,
                _id: this.customRoleSelected._id,
                name: this.customRoleSelected.name,
                placeId: this.placeId,
                customRole: true,
              },
              placeId: this.placeId,
              resourceGroup: Array.isArray(this.placeResource) ? this.placeResource[0] : this.placeResource,
            }
          }
          if (userId) {
            dialog.confirm($translate.instant('active_permisos_change'))
              .then(() => {
                this.user.permissions = this.depopulatePermissions(this.user.permissions);
                return UserService.Update(this.user)
                  .then(() => {
                    NotificationService.Success($translate.instant('user_{name}_update_succ').replace('{name}', this.user.local.email));
                    $uibModalInstance.close('cancel');
                  })
                  .catch(err => {
                    ErrorService.handler(err);
                    $uibModalInstance.close('cancel');
                  });
              })
              .catch(() => {
                NotificationService.Information($translate.instant('operation_cancelled'));
                $uibModalInstance.close('cancel');
              });
          } else {
            this.user.permissions = this.depopulatePermissions(this.user.permissions);
            this.user.profile.language = PlaceService.GetCurrent().language;
            UserService.Create(this.user)
              .then(() => {
                NotificationService.Success(`Usuario ${this.user.local.email} creado correctamente.`);
                $uibModalInstance.close('cancel');
              })
              .catch(err => {
                ErrorService.handler(err);
                $uibModalInstance.close('cancel');
              });
          }
        });
    }
  };

  this.selectResourceGroup = resourceGroup => {
    this.user.permissions.push({
      placeId: this.placeId,
      resourceGroup,
    });
    generateAlreadyShown();
  };

  const removeIfExists = (permission, permissions) => {
    const index = indexOf(permissions, permission);
    permissions.splice(index, 1);
  }

  this.removePermission = permission => {
    removeIfExists(permission, this.user.permissions)
    generateAlreadyShown();
  };

  this.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  this.depopulatePermissions = function (permissions) {
    return permissions.map(permission => (
      {
        placeId: permission.placeId,
        resourceGroup: permission.resourceGroup._id,
        role: permission.role ? permission.role._id : this.globalUserPermission._id,
        boxLabel: permission.boxLabelEnabled && permission.boxLabelVisible? permission.boxLabel: '',
      }
    ));
  };

  this.checkPlacePermission = () => {
    // If global permission is not operator
    if (this.placeId && this.globalUserPermission.name === 'Statistical') {
      this.user.permissions = this.user.permissions.filter(permission => {
        const isPlaceType = permission.resourceGroup.type === 'place';
        const isFromThisPlace = permission.resourceGroup.placeId === this.placeId;
        return !isFromThisPlace || isFromThisPlace && isPlaceType;
      });
    }

    if (this.placeId && this.globalUserPermission.name !== 'operator') {
      let placePermissionFound = false;
      // Look over all user's permissions
      this.user.permissions.forEach(permission => {
        if (permission.resourceGroup.type === 'place' && permission.resourceGroup.placeId === this.placeId) {
          this.placeResource = permission.resourceGroup;
          permission.role = this.globalUserPermission;
          placePermissionFound = true;
        }
      });

      if (!placePermissionFound) {
        return ResourceGroupService.GetAllAsPromise()
          .then(resources =>
            $timeout(() => {
              const PlaceResourceGroup = filter(resources, { type: 'place', placeId: this.placeId });
              this.placeResource = PlaceResourceGroup;
              this.user.permissions.push({
                placeId: this.placeId,
                resourceGroup: PlaceResourceGroup[0],
                role: this.globalUserPermission,
                boxLabel: '',
              });
              return;
            })
          )
          .catch(ErrorService.handler);
      } else {
        return Promise.resolve();
      }
    } else if (this.placeId && this.globalUserPermission.name === 'operator') {
      // Look over all user's permissions
      remove(this.user.permissions, permission =>
        permission.resourceGroup.type === 'place' && permission.resourceGroup.placeId === this.placeId
      );
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  };

  const generateAlreadyShown = () => {
    this.alreadyShown = {};
    forEach(this.user.permissions, permission => {
      if (permission.resourceGroup) {
        this.alreadyShown[permission.resourceGroup._id] = permission.resourceGroup;
      }
    });
  };

};

module.exports = module.controller('UserModalController', UserModalController);
