import angular from 'angular';

const module = angular.module('whyline.controllers.sign-in', [
  'whyline.constants',
]);

const SignInController = (
  ADMIN_URL,
) => {
  const url = new URL(`${ADMIN_URL}external`);
  url.searchParams.append('url', `/login`);
  console.log(url.toString());
  location.href = url;
};

module.exports = module.controller('SignInController', SignInController);
