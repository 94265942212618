import angular from 'angular';
import { localStorage } from '../utils';

const module = angular.module('whyline.controllers.display-tv', [
  'whyline.constants',
]);

const DisplayTvController = (
  $state,
  DISPLAY_URL,
) => {
  const placeId = $state.params.placeId;
  const displayId = $state.params.displayId;
  const token = localStorage.get('token');
  const url = new URL(`${DISPLAY_URL}external`);
  url.searchParams.append('token', token);
  url.searchParams.append('url', `/place/${placeId}/display/${displayId}`);
  console.log(url.toString());
  location.href = url;
};

module.exports = module.controller('DisplayTvController', DisplayTvController);
