import angular from 'angular';
import { cloneDeep } from 'lodash';
import { Promise } from 'es6-promise';
import '../resources/place';
import '../resources/user';
import '../resources/area';
import '../resources/line';
import '../services/errors';
import './globals';
import '../app.constants';

const module = angular.module('whyline.services.place', [
  'whyline.services.globals',
  'whyline.services.user',
  'whyline.services.errors',
  'whyline.resources.place',
  'whyline.resources.area',
  'whyline.resources.line',
  'whyline.constants',
]);

let GlobalsService;
let UserService;
let Area;
let Line;
let Place;
let ErrorService;
let $timeout;
let $state;
let SAFE_STATES;

class PlaceService {
  static $inject = [
    'GlobalsService',
    'UserService',
    'Place',
    '$timeout',
    'Area',
    'Line',
    'ErrorService',
    '$state',
    'SAFE_STATES',
  ];

  constructor(
    injectedGlobalsService,
    injectedUserService,
    injectedPlace,
    inj$timeout,
    injArea,
    injLine,
    injErrorService,
    inj$state,
    injSAFE_STATES,
  ) {
    /*@ngInject*/
    GlobalsService = injectedGlobalsService;
    UserService = injectedUserService;
    Place = injectedPlace;
    $timeout = inj$timeout;
    Area = injArea;
    Line = injLine;
    ErrorService = injErrorService;
    $state = inj$state;
    SAFE_STATES = injSAFE_STATES;
  }

  Create(data) {
    const newPlace = new Place(data);
    return Place.Save(newPlace);
  }

  Copy(place) {
    const copy = cloneDeep(place);
    return new Place(copy);
  }

  Update(place) {
    console.log(`Update solo. ${place}`);
    return Place.Save(place);
  }

  UpdateWithCallLogic(place, applyIn) {
    console.log(`Update with call logic. ${JSON.stringify(place)}`);
    return Place.UpdateWithCallLogic(place, applyIn);
  }

  GetOne(placeId) {
    return Place.FindById(placeId);
  }

  GetOneAsPromise(placeId) {
    return Place.FindById(placeId, { promise: true });
  }

  GetOneAsPromiseFromServer(placeId) {
    return Place.FindById(placeId, {
      promise: true,
      force: true,
    });
  }

  GetAll() {
    return Place.FetchAll();
  }

  GetAllAllowedByUserAsPromise() {
    return Place.FetchAll({ promise: true });
  }

  GetByIds(ids) {
    return Place.FindByIds(ids, { force: true, promise: true });
  }

  GetAllAsPromiseFromServer() {
    const placesInCache = Place.GetAllFromCache();
    let ids = Object.keys(placesInCache);
    if (!ids.length) {
      const user = UserService.GetCurrent();
      ids = Object.keys(user.roles);
    }
    return Place.FetchAll(ids, { promise: true, force: true });
  }

  SetCurrent(place) {
    GlobalsService.SetCurrentPlace(place);
    Place.TruncateAll();
  }

  GetAllPlaceServices(placeId) {
    return Place.GetAllPlaceServices(placeId);
  }

  GetCurrent() {
    if (!GlobalsService.CurrentOrganization) {
      return $state.go(SAFE_STATES.organizationsList);
    } else if (GlobalsService.CurrentPlace) {
      return Place.FindById(GlobalsService.CurrentPlace._id);
    }
    return $state.go(SAFE_STATES.placesList);
  }

  getIdentificationsForCurrentPlace() {
    return Place.getIdentifications(GlobalsService.CurrentPlace._id);
  }

  GetCurrentAsPromise() {
    if (!GlobalsService.CurrentPlace) {
      return Promise.resolve(null);
    }
    return Place.FindById(GlobalsService.CurrentPlace._id, { promise: true });
  }

  GetCurrentCheckInCode() {
    return Place.FindById(GlobalsService.CurrentPlace._id).checkInCode;
  }

  GetCurrentWith(models = [], options = {}) {
    if (options.promise) {
      return new Promise((resolve) =>
        resolve(Place.FindById(GlobalsService.CurrentPlace._id, { populate: models })),
      );
    }
    return Place.FindById(GlobalsService.CurrentPlace._id, { populate: models });
  }

  GetCurrentPopulatedAsPromise() {
    return Area.FetchAll({ promise: true })
      .then(() => Line.FetchAll({ promise: true }))
      .then(() =>
        Place.FindById(GlobalsService.CurrentPlace._id, {
          populate: ['lines', 'areas'],
          promise: true,
        }),
      )
      .then((newData) => {
        newData.items = angular.extend(newData.areasPopulated, newData.linesPopulated);
        return newData;
      })
      .catch(ErrorService.handler);
  }

  GetAllEnterprise() {
    return Place.FetchAllEnterprise();
  }

  GetAllEnterpriseAsPromise() {
    return Place.FetchAllEnterprise({ promise: true });
  }

  UpdateImageByType(file, imageType, placeId) {
    return Place.UpdateImageByType(file, imageType, placeId);
  }

  DeleteImageByType(placeId, imageType) {
    return Place.DeleteImageByType(placeId, imageType);
  }

  SaveImages(placeId, files) {
    return Place.SaveImages(placeId, files);
  }

  Merchant(place) {
    return Place.Merchant(place);
  }

  GetAliasTypes() {
    return Place.GetAliasTypes();
  }

  EnableScenario(scenario, placeId) {
    return Place.EnableScenario(scenario, placeId);
  }

  SetDefaultScenario(scenario, placeId) {
    return Place.SetDefaultScenario(scenario, placeId);
  }
}

module.exports = module.service('PlaceService', PlaceService);
