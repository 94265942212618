import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/organization-line';

const module = angular.module('whyline.services.organization-line', [
  'whyline.resources.organization-line'
]);

let OrganizationLine;

class OrganizationLineService {

  static $inject = ['OrganizationLine'];

  constructor(injectedOrganizationLine) {
    OrganizationLine = injectedOrganizationLine;
  }

  Create(data) {
    const newOrganizationLine = new OrganizationLine(data);
    return OrganizationLine.Save(newOrganizationLine);
  }

  Copy(organizationLine) {
    const copy = cloneDeep(organizationLine);
    return new OrganizationLine(copy);
  }

  /*CallNext(lineId) {
    return OrganizationLine.CallNext(lineId);
  }

  CallTo(subscriptionId, lineId) {
    return Line.CallTo(subscriptionId, lineId);
  }*/

  Update(line) {
    return OrganizationLine.Save(line);
  }
  /**
   * Update partial fields of Organization
   *
   * @param {Object} options
   * @param {Object} options.data
   * @param {String} options.organizationId
   * @param {String} options.organizationLineId
   * @return {Object}
   * @public
   * @static
   */
  UpdatePartial(options) {
    return OrganizationLine.SavePartial(options);
  }

  Remove(lineId) {
    const lineToRemove = OrganizationLine.FindById(lineId);
    return OrganizationLine.Remove(lineToRemove);
  }

  GetMyLinesAsPromise() {
    return OrganizationLine.GetMyLines({ promise: true });
  }

  GetAll() {
    return OrganizationLine.FetchAll();
  }

  GetAllFromServer() {
    return OrganizationLine.FetchAll({force: true});
  }

  GetAllAsPromise() {
    return OrganizationLine.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return OrganizationLine.FetchAll({ promise: true, force: true});
  }

  GetOne(lineId) {
    return OrganizationLine.FindById(lineId);
  }

  GetOneFromServer(lineId) {
    return OrganizationLine.FindById(lineId, {force: true});
  }

  GetOneAsPromiseFromServer(lineId) {
    return OrganizationLine.FindById(lineId, {promise: true, force: true});
  }

  GetOneAsPromise(lineId) {
    return OrganizationLine.FindById(lineId, {promise: true});
  }

  FindByIds(ids, options) {
    return OrganizationLine.FindByIds(ids, options);
  }

  Switch(line, enabled, enableInPlaces) {
    return line.enable(enabled, enableInPlaces);
  }

  Clear(line) {
    return line.clear();
  }

}

module.exports = module.service('OrganizationLineService', OrganizationLineService);
