import angular from 'angular';
import { cloneDeep, concat, get } from 'lodash';
import '../resources/device';


const module = angular.module('whyline.services.device', [
  'whyline.resources.device',
]);

let Device;
let $timeout;

const handleError = err => {
  console.error(err);
  throw err;
};

class DeviceService {

  static $inject = ['Device', '$timeout'];

  constructor(injectedDevice, injected$timeout) {
    Device = injectedDevice;
    $timeout = injected$timeout;
  }

  GetLinked() {
    return Device.getLinkedDevices(false);
  }

  GetLinkedAsPromise() {
    return Device.getLinkedDevices(true);
  }

  GetUnlinked() {
    return Device.getUnlinkedDevices();
  }

  GetById(deviceId) {
    return Device.getById(deviceId);
  }

  LinkToPlace(device) {
    return Device.linkToPlace(device.name, device.alias);
  }

  UnlinkFromPlace(deviceId) {
    return Device.unlinkFromPlace(deviceId);
  }
  Update(device) {
    return Device.rename(device._id, device.alias);
  }

}

module.exports = module.service('DeviceService', DeviceService);
