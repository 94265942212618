import angular from 'angular';
import './resource';
import './area';
import './line';
import './schema';
import './user';
import './display';
import './subscription';
import _ from 'lodash';

const module = angular.module('whyline.resources.place', [
  'whyline.resources.resource',
  'whyline.resources.area',
  'whyline.resources.line',
  'whyline.resources.display',
  'whyline.resources.schema',
  'whyline.resources.user',
  'whyline.resources.subscription',
]);

const PlaceFactory = (
  $timeout,
  $http,
  API_URL,
  Resource,
  Area,
  Line,
  Display,
  Schema,
  User,
  Subscription,
  Upload,
  $translate,
) => {
  /*@ngInject*/
  // API REST Resource Name
  const _type = 'place?organizationId';
  const apiEndPoint = `${API_URL}${_type}`;

  const _schema = {
    _id: {
      type: String,
    },
    images: {
      type: Object,
    },
    defaultLineFlowTypeOrganization: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    googlePlaceId: {
      type: String,
      required: true,
    },
    lines: {
      type: [Line],
      client: true,
      default: [],
    },
    displays: {
      type: [Display],
      client: true,
      default: [],
    },
    areas: {
      type: [Area],
      client: true,
      default: [],
    },
    users: {
      type: [User],
      client: true,
      default: [],
    },
    hasEnterprise: {
      type: Boolean,
      required: true,
      default: false,
    },
    info: {
      type: Object,
      required: true,
    },
    openingDetails: {
      type: Object,
    },
    location: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
      default: [],
    },
    timezone: {
      type: String,
      required: true,
    },
    branchId: {
      type: String,
      default: '',
    },
    hiddenInMobileApp: {
      type: Boolean,
    },
    enabledForMobileApp: {
      type: Boolean,
    },
    configuration: {
      aliasType: {
        type: String,
      },
      checkIn: {
        min: {
          type: Number,
        },
        max: {
          type: Number,
        },
      },
      canCloneAndForward: {
        default: false,
        type: Boolean,
      },
      canConfirmAppointments: {
        type: Boolean,
      },
      canConfirmSpontaneous: {
        default: false,
        type: Boolean,
      },
      canViewTotemToggles: {
        default: false,
        type: Boolean,
      },
      canProcessAppointment: {
        default: false,
        type: Boolean,
      },
      canEditAppointments: {
        default: true,
        type: Boolean,
      },
      canGiveAppointments: {
        default: false,
        type: Boolean,
      },
      canSkipProcessOrder: {
        type: Boolean,
        default: false,
      },
      closedMessage: {
        type: String,
      },
      checkInTolerance: {
        type: Number,
      },
      requireAppointmentConfirmation: {
        default: false,
        type: Boolean,
      },
      requeue: {
        enabled: {
          default: false,
          type: Boolean,
        },
        limit: {
          in: {
            minutes: {
              default: 60,
              type: Number,
            },
          },
        },
      },
      retained: {
        enabled: {
          default: false,
          type: Boolean,
        },
        days: {
          default: 90,
          type: Number,
        },
      },
      showWaitingInLineInPlace: {
        default: true,
        type: Boolean,
      },
      appointmentReminder: {
        enabled: {
          default: false,
          type: Boolean,
        },
      },
      authorizedAndCourtesyDefault: {
        default: false,
        type: Boolean,
      },
      totemNotificationBoti: {
        default: false,
        type: Boolean,
      },
      sla: {
        default: 0,
        type: Number,
      },
      canUseDesktopCaller: {
        default: 0,
        type: Number,
      },
      sigehosQuery: {
        default: false,
        type: Boolean,
      }
    },
    videos: {
      type: Array,
      default: [],
    },
    checkInCode: {
      type: String,
    },
    mobileAppConfig: {
      autoCheckIn: { type: Boolean, default: false },
      showPeopleAheadAfterCheckIn: { type: Boolean, default: true },
      showCheckInButton: { type: Boolean, default: true },
      showQRCode: { type: Boolean, default: false },
      showWaitingTime: { type: Boolean, default: true },
    },
    plan: {
      name: { type: String },
      type: { type: String },
      maxLine: { type: Number },
      maxAreas: { type: Number },
      maxDisplays: { type: Number },
      maxTotems: { type: Number },
      maxSectors: { type: Number },
      hasLimits: { type: Boolean },
    },
    requirements: {
      virtualine: {
        type: Object,
      },
      identifications: [],
      phoneNumber: {
        type: String,
      },
      phoneCompany: {
        type: String,
      },
      name: {
        type: String,
      },
      gender: {
        type: String,
      },
      address: {
        type: String,
      },
      birthdate: {
        type: String,
      },
    },
    organizationId: {
      type: String,
    },
    scenarios: [
      {
        scenarioId: String,
        name: String,
        enabled: Boolean,
        areaId: String,
        conditionId: String,
      },
    ],
  };

  const Place = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.GetRequiredFieldsForPerson = function () {
      if (!this.requirements) return [];
      let required = _.keysIn(this.requirements.virtualine);

      return _.filter(required, (req) => this.requirements.virtualine[req]);
    };
  };

  Place.UpdateWithCallLogic = (place, applyIn) => {
    const placeToUpdate = Place.Clean(place);
    const url = `${API_URL}${_type}/${place._id}?applyIn=${applyIn}`;
    return $http.put(url, placeToUpdate).then((response) => {
      return Promise.resolve(response.data);
    });
  };

  Place.prototype = new Resource(_type, _schema, Place);

  Resource.Extend(_type, Place);

  //one hour
  const validityTime = 60 * 60 * 1000;
  Place.InitCache(validityTime);

  Place.FetchAllEnterprise = (options) => {
    const url = `${API_URL}${_type}/virtual-line`;
    Place.TruncateCache();
    const promise = $http.get(url).then((response) => {
      $timeout(() => {
        Place.UpdateCacheArray(response.data, options);
      });
      return Place.GetAllFromCache();
    });
    if (options && options.promise) {
      return promise;
    } else {
      return Place.GetAllFromCache();
    }
  };

  /**
   * Empties the caches for all childs
   */
  Place.RestartCaches = () => {
    Area.TruncateCache();
    Line.TruncateCache();
    Display.TruncateCache();
    Schema.TruncateCache();
    User.TruncateCache();
    Subscription.TruncateCache();
    Area.FetchAll();
    Line.FetchAll();
    Display.FetchAll();
    Schema.FetchAll();
    User.FetchAll();
    Subscription.FetchAll();
  };

  Place.TruncateAll = () => {
    Area.TruncateCache();
    Line.TruncateCache();
    Display.TruncateCache();
    Schema.TruncateCache();
    User.TruncateCache();
    Subscription.TruncateCache();
  };

  Place.UpdateImageByType = (file, type, placeId) => {
    if (file && type && placeId) {
      return Upload.upload({
        url: `${apiEndPoint}/${placeId}/image`,
        method: 'POST',
        data: {
          imageType: type,
          file,
          placeId,
        },
      });
    }
  };

  Place.GetAllPlaceServices = (placeId) => {
    const url = `${apiEndPoint}/${placeId}/services`;
    return $http.get(url).then((response) => response.data);
  };

  Place.SaveImages = (placeId, files) => {
    if (files && placeId) {
      let arrFiles = [];
      let fields = [];
      for (let key in files) {
        if (files.hasOwnProperty(key)) {
          if (!files[key]) {
            files[key] = [];
          }
          arrFiles.push(Upload.rename(files[key], key));
          fields.push({
            name: key,
            maxCount: 1,
          });
        }
      }
      return Upload.upload({
        url: `${apiEndPoint}/${placeId}/images`,
        arrayKey: '',
        method: 'POST',
        data: {
          files: arrFiles,
          placeId,
          fields,
        },
      });
    }
  };

  Place.DeleteImageByType = (placeId, imageType) =>
    $http.delete(`${apiEndPoint}/${placeId}/image/${imageType}`);

  Place.Merchant = (place) => {
    const url = `${API_URL}${_type}/merchant`;
    return $http.post(url, place).then((response) => {
      return response.data;
    });
  };

  Place.getIdentifications = (placeId) => {
    const identificationsInPlace = _.get(
      Place.FindById(placeId),
      'requirements.identifications',
      [],
    );
    const identifications = _(identificationsInPlace)
      .filter((id) => id.show)
      .map((id) => {
        id.name = $translate.instant(id.name).toUpperCase();
        return id;
      })
      .value();

    return identifications;
  };

  Place.GetAliasTypes = () => {
    const url = `${API_URL}${_type}/get-alias-types`;
    return $http.get(url).then((response) => {
      return response.data;
    });
  };

  Place.EnableScenario = (scenario, placeId) => {
    const url = `${apiEndPoint}/${placeId}/enable-scenario`;
    return $http.post(url, { scenario }).then((response) => response.data);
  };

  Place.SetDefaultScenario = (scenario, placeId) => {
    const url = `${apiEndPoint}/${placeId}/default-scenario`;
    return $http.post(url, { scenario }).then((response) => response.data);
  };

  return Place;
};

module.exports = module.factory('Place', PlaceFactory);
