import angular from 'angular';
import tzlookup from 'tz-lookup';
import { cloneDeep, debounce, get, includes, isNil, isUndefined } from 'lodash';
import { localStorage, options } from '../../utils';
import '../../services/globals';
import '../../services/timezone';
import '../../services/user';
import '../../services/auth';
import '../../services/language';
import '../../services/location';
import '../../services/line-flow-type-organization';
import '../../services/place';
import '../../services/errors';
import '../../services/category';
import '../../services/country';
import '../../services/place-messages';
import '../../services/organization-scenario';

const module = angular.module('whyline.modals.place', [
  'ngMap',
  'whyline.services.globals',
  'whyline.services.timezone',
  'whyline.services.user',
  'whyline.services.auth',
  'whyline.services.language',
  'whyline.services.location',
  'whyline.services.line-flow-type-organization',
  'whyline.services.place',
  'whyline.services.errors',
  'whyline.services.category',
  'whyline.services.country',
  'whyline.directives.focusme',
  'whyline.services.place-messages',
  'whyline.services.organization-scenario',
]);

const PlaceModalController = (
  // Imports
  $scope,
  $uibModalInstance,
  $translate,
  $timeout,
  Slug,
  dialog,
  // Resolve
  placeId,
  organization,
  // Services
  UserService,
  LocationService,
  LanguageService,
  TimezoneService,
  PlaceService,
  AuthService,
  NotificationService,
  ErrorService,
  CategoryService,
  CountryService,
  LineFlowTypeOrganizationService,
  PlaceMessagesService,
  OrganizationScenarioService
) => {
  'ngInject';

  $scope.validEmail =
    /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,5})$/;
  $scope.urlPattern =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  $scope.blockSaveButton = false;
  $scope.editCallLogicsScope = {
    options: options.filter((o) => o.onlyPlace || o.placeLines),
    selectedOption: options.filter((o) => o.onlyPlace || o.placeLines)[0].key,
  };

  $scope.selectedDefaultIdentification = {
    value: undefined,
  };

  localStorage.get('user').then(user => {
    $scope.superAdmin = user && user.sudo;
    $scope.canEditDesktopCaller = checkDesktopCallerPermissions(user);
  });

  $scope.enterpriseType = organization.configuration.queuer.enterpriseType;
  $scope.showButtonShowPeopleAheadAfterCheckIn =
    organization.configuration.mobileApp.showPeopleAheadAfterCheckIn;
  $scope.placeMessages = [];
  $scope.file = [];

  $scope.onPlanChanged = () => {
    $scope.place.plan.name = '';
  };

  $scope.setDefaultIndentificationsList = () => {
    const currentIdentifications = $scope.place.requirements.identifications;
    $scope.availableDefaultIdentifications = currentIdentifications.filter(
      (id) => id.show
    );
    $scope.selectedDefaultIdentification.value =
      currentIdentifications.find((id) => id.default) ||
      currentIdentifications.find((id) => id.empty);
  };

  $scope.updateDefaultIdentification = () => {
    const defaultKey = get(
      $scope.selectedDefaultIdentification,
      'value.key',
      undefined
    );

    const identifications = $scope.place.requirements.identifications;

    identifications.forEach((id) => {
      id.default = false;
      return id;
    });

    if (!defaultKey) {
      return;
    }

    const defaultId = identifications.find((id) => id.key === defaultKey);

    if (defaultId) {
      defaultId.default = true;
    }
  };

  // Init
  $scope.forms = {};
  $scope.status = {
    user: {
      isOpen: !placeId,
      isDisabled: !!placeId,
    },
    location: {
      isOpen: !!placeId,
      isDisabled: !placeId,
    },
    information: {
      isOpen: false,
      isDisabled: true,
      shouldNameBeFocused: false,
    },
    configuration: {
      isOpen: false,
      isDisabled: !placeId,
    },
    messages: {
      isOpen: false,
      isDisabled: true,
    },
    noPlace: false,
    showTooltip: false,
  };

  $scope.languages = {
    list: {
      en: 'English',
      es: 'Español',
      pt: 'Portugués',
    },
  };

  const SUPPORTED_LANGUAGES = ['en', 'es', 'pt'];
  const DEFAULT_LANGUAGE = 'en';
  const language = includes(SUPPORTED_LANGUAGES, $translate.use())
    ? $translate.use()
    : DEFAULT_LANGUAGE;

  const field = `name_${language}`;

  $scope.categoriesSettings = {
    template: `<b>{{option.${field}}}</b>`,
    externalIdProp: 'category',
    displayProp: field,
    idProp: '_id',
    searchField: field,
    enableSearch: true,
    keyboardControls: true,
    selectedToTop: true,
    selectionLimit: 3,
    scrollable: true,
  };

  $scope.multiselectTranslations = {
    checkAll: $translate.instant('multiselect_todos'),
    uncheckAll: $translate.instant('multiselect_ninguno'),
    dynamicButtonTextSuffix: $translate.instant('multiselect_seleccionados'),
    buttonDefaultText: $translate.instant('multiselect_seleccionar'),
    searchPlaceholder: $translate.instant('multiselect_buscar'),
    selectionCount: $translate.instant('multiselect_seleccionados'),
  };
  $scope.userExists = true;
  $scope.canComplete = false;
  $scope.displayBackground = '';
  $scope.displayColor = '';
  $scope.totemBackground = '';
  $scope.totemColor = '';
  const emptyPlace = {
    name: '',
    language: LanguageService.GetBrowserLanguage(),
    categories: organization.categories,
    timezone: '',
    branchId: '',
    enabledForMobileApp: organization.enabledForMobileApp,
    hiddenInMobileApp: organization.hiddenInMobileApp,
    configuration: {
      aliasType: organization.configuration.queuer.aliasType,
      appointmentReminder: {
        enabled: get(
          organization,
          'configuration.appointmentReminder.enabled',
          false
        ),
      },
      totemNotificationBoti: false,
      authorizedAndCourtesyDefault: false,
      boxLabelRequired: organization.configuration.queuer.boxLabelRequired,
      canCloneAndForward: organization.configuration.queuer.canCloneAndForward,
      canConfirmAppointments:
        organization.configuration.queuer.canConfirmAppointments,
      canConfirmSpontaneous: false,
      canEditAppointments:
        organization.configuration.queuer.canEditAppointments,
      canGiveAppointments:
        organization.configuration.queuer.canGiveAppointments,
      checkIn: {
        min: organization.configuration.checkIn.min,
        max: organization.configuration.checkIn.max,
      },
      requireAppointmentConfirmation:
        organization.configuration.queuer.requireAppointmentConfirmation,
      canProcessAppointment:
        organization.configuration.queuer.canProcessAppointment,
      canSendAppointmentDelayAlert:
        organization.configuration.mobileApp.canSendAppointmentDelayAlert ||
        false,
      checkInTolerance: organization.configuration.queuer.checkInTolerance || 2,
      closedMessage: organization.configuration.queuer.closedMessage,
      canSkipProcessOrder:
        organization.configuration.queuer.canSkipProcessOrder,
      requeue: organization.configuration.queuer.requeue,
      retained: organization.configuration.queuer.retained,
      showWaitingInLineInPlace:
        organization.configuration.queuer.showWaitingInLineInPlace,
      showWorkingDay: organization.configuration.queuer.showWorkingDay,
      sla: 0,
      display: {
        ttsEnabled: {
          enabled: false,
          enabledFor: ''
        },
        color: '#000000',
        background: '#ffffff'
      },
      totem: {
        color: '#000000',
        background: '#ffffff'
      }
    },
    requirements: organization.configuration.requirements,
    location: {},
    info: {
      website: '',
      phones: [],
      address: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      street: '',
      streetNumber: '',
    },
    openingDetails: {},
    geoLocation: [],
    checkInCode: '',
    mobileAppConfig: {
      showPeopleAheadAfterCheckIn: true,
      showCheckInButton: true,
      showWaitingTime: true,
      showQRCode: false,
    },
    userAdmin: {
      local: {
        email: '',
        password: '',
      },
      profile: {
        firstName: '',
        lastName: '',
      },
    },
  };

  $scope.lineFlowTypeOrganizationList = [];
  $scope.lineFlowTypeOrganizationSelected = {};

  $scope.setLFTOId = (lftoSelected) => {
    $scope.place.defaultLineFlowTypeOrganization = lftoSelected._id;
  };

  $scope.defaultAppointmentConfirmation = () => {
    if (
      !$scope.place.configuration.canConfirmAppointments ||
      !$scope.place.configuration.canGiveAppointments
    ) {
      $scope.place.configuration.requireAppointmentConfirmation = false;
    }
    if (!$scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.showWorkingDay = false;
    }
    if (!$scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.canProcessAppointment = false;
    }
    if ($scope.place.configuration.canGiveAppointments) {
      $scope.place.configuration.canConfirmAppointments = true;
    }
  };

  // Data for angular wizard
  const categoriesPromise = CategoryService.GetAllAsPromise();
  const aliasTypesPromise = PlaceService.GetAliasTypes();
  const organizationScenariosPromise = OrganizationScenarioService.GetAllAsPromiseFromServer();

  Promise.all([categoriesPromise, aliasTypesPromise, organizationScenariosPromise]).then(
    ([categories, aliasTypes, organizationScenarios]) => {
      $scope.data = {
        confirmPassword: '',
        phone: '',
        locationAsString: '',
        address: '',
        virtualineImage: '',
        mobileAppImage: '',
        results: [],
        addressResults: [],
        selectedCategory: [],
        categories,
        aliasTypes,
        selectedPlace: '',
        timezones: TimezoneService.List,
        addressSearch: '',
        selectedAddress: '',
      };

      if (placeId) {
        PlaceService.GetOneAsPromise(placeId)
          .then(place => {
            $timeout(async () => {
              $scope.place = PlaceService.Copy(place);
              $scope.data.selectedPlace = PlaceService.Copy(place);
              $scope.data.phone = $scope.place.info.phones[0] || '';
              $scope.status.showTooltip = true;
              $scope.data.locationAsString = [
                place.location.lat,
                place.location.lng,
              ];
              if (!$scope.place.configuration.display) {
                $scope.place.configuration.display = {
                  ttsEnabled: {
                    enabled: false,
                    enabledFor: 'all'
                  },
                  color: '#000000',
                  background: '#ffffff'
                };
              }
              if (!$scope.place.configuration.totem) {
                $scope.place.configuration.totem = {
                  color: '#000000',
                  background: '#ffffff'
                };
              }
              $scope.displayBackground = get(
                $scope.place,
                'configuration.display.background',
                '#ffffff',
              );
              $scope.displayColor = get(
                $scope.place,
                'configuration.display.color',
                '#000000',
              );
              $scope.totemBackground = get(
                $scope.place,
                'configuration.totem.background',
                '#ffffff',
              );
              $scope.totemColor = get(
                $scope.place,
                'configuration.totem.color',
                '#000000',
              );
              $scope.data.address = $scope.place.info.address;
              $scope.logoSrc =
                $scope.place.images.logo || 'img/common/placeholder-place.png';
              PlaceMessagesService.GetAllAsPromise(placeId)
                .then(messages => {
                  $timeout(() => {
                    $scope.placeMessages = messages;
                  });
                })
                .catch(ErrorService.handler);

              // Set categories
              $scope.place.categories.forEach((category) => {
                let c = $scope.data.categories.find(
                  (cate) => category == cate.key
                );
                if (c) {
                  $scope.data.selectedCategory.push(c);
                }
              });

              await setCountryAndIdentifications($scope.place);

              LineFlowTypeOrganizationService.GetAllEnabledByPlace(
                placeId,
                true
              ).then((lftoList) => {
                $scope.lineFlowTypeOrganizationList = lftoList;
                const found = $scope.lineFlowTypeOrganizationList.find(
                  (lfto) =>
                    lfto._id == $scope.place.defaultLineFlowTypeOrganization
                );
                $scope.lineFlowTypeOrganizationSelected = found
                  ? found
                  : $scope.lineFlowTypeOrganizationList[0];
              });
            });
            const enabledForTts = get(place, 'configuration.display.ttsEnabled.enabledFor', 'all');
            $scope.enabledFor = enabledForTts === 'all';
          })
          .catch(ErrorService.handler);
      } else {
        $scope.place = cloneDeep(emptyPlace);
        $scope.enabledFor = true;
        LineFlowTypeOrganizationService.GetAllEnabledByOrganization(
          organization._id,
          true
        ).then((lftoList) => {
          $scope.lineFlowTypeOrganizationList = lftoList;
          const found = $scope.lineFlowTypeOrganizationList.find(
            (lfto) => lfto._id == organization.defaultLineFlowTypeOrganization
          );
          $scope.lineFlowTypeOrganizationSelected = found
            ? found
            : $scope.lineFlowTypeOrganizationList[0];
          $scope.place.defaultLineFlowTypeOrganization =
            $scope.lineFlowTypeOrganizationSelected._id;
          emptyPlace.defaultLineFlowTypeOrganization =
            $scope.place.defaultLineFlowTypeOrganization;
        });
        $scope.place.categories.forEach((category) => {
          let c = $scope.data.categories.find((cate) => category == cate.key);
          if (c) {
            $scope.data.selectedCategory.push(c);
          }
        });

        const useScenarios = organization.configuration.queuer.useScenarios;
        if (useScenarios) {
          const scenarios = Object.values(organizationScenarios)
            .filter(({ organizationId, isDefault, isDeleted }) =>
              organizationId === organization._id && isDefault && !isDeleted
            )
            .map(({ _id, isDeleted, organizationId, ...rest }) => ({
              ...rest,
              scenarioId: _id,
              enabled: true,
            }));
          $scope.place.scenarios = scenarios;
        }
      }
    }
  );

  function someIdentificationChecked() {
    const identifications = $scope.place.requirements.identifications
      .filter((id) => isUndefined(id.empty))
      .filter((identification) => identification.show);

    return identifications.length;
  }

  function noIdentificationChecked() {
    return !someIdentificationChecked();
  }

  const validate = () => {
    const name = get($scope, 'place.name');
    const address = get($scope, 'place.info.address');
    const timezone = get($scope, 'place.timezone');
    const language = get($scope, 'place.language');
    const checkInTolerance = get(
      $scope,
      'place.configuration.checkInTolerance'
    );
    const retained = get($scope, 'place.configuration.retained');
    const requirements = get($scope, 'place.requirements');
    const phoneNumber = get($scope, 'data.phone');
    const city = get($scope, 'place.info.city');
    const state = get($scope, 'place.info.state');
    const zipCode = get($scope, 'place.info.zipCode');
    const country = get($scope, 'place.info.country');
    const street = get($scope, 'place.info.street');
    const streetNumber = get($scope, 'place.info.streetNumber');
    const lineFlowTypeOrganization = get(
      $scope,
      'place.defaultLineFlowTypeOrganization'
    );
    const sla = get($scope, 'place.configuration.sla');
    const checkInMin = get($scope, 'place.configuration.checkIn.min', null);
    const checkInMax = get($scope, 'place.configuration.checkIn.max', null);

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_place_name'));
      return false;
    }

    if (
      name &&
      !/^[a-zA-Zàáâäãåąč&ćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ\-ð:/ ,.'-+\d.*"]+$/u.test(
        name
      )
    ) {
      NotificationService.Warning($translate.instant('invalid_place_name'));
      return false;
    }

    if (name.length > 100) {
      NotificationService.Warning($translate.instant('place_name_too_long'));
      return false;
    }

    if (!phoneNumber) {
      NotificationService.Warning($translate.instant('mandatory_phone_place'));
      return;
    }

    if (!language) {
      NotificationService.Warning($translate.instant('mandatory_lang_place'));
      return false;
    }

    if (!timezone) {
      NotificationService.Warning(
        $translate.instant('mandatory_timezone_place')
      );
      return false;
    }

    if (!city) {
      NotificationService.Warning($translate.instant('mandatory_city_place'));
      return false;
    }

    if (!state) {
      NotificationService.Warning($translate.instant('mandatory_state_place'));
      return false;
    }

    if (!zipCode) {
      NotificationService.Warning($translate.instant('mandatory_zip_place'));
      return false;
    }

    if (!country) {
      NotificationService.Warning(
        $translate.instant('mandatory_country_place')
      );
      return false;
    }

    if (!address || !street || !streetNumber) {
      NotificationService.Warning(
        $translate.instant('mandatory_address_place')
      );
      return false;
    }

    if (
      !checkInTolerance ||
      checkInTolerance > 100 ||
      checkInTolerance < 2 ||
      checkInTolerance.toString().indexOf('.') != -1 ||
      checkInTolerance.toString().indexOf(',') != -1
    ) {
      NotificationService.Warning(
        $translate.instant('place_checkin_tolerance_range')
      );
      return false;
    }

    if (
      retained.enabled &&
      (retained.days <= 0 || retained.days === undefined)
    ) {
      NotificationService.Warning($translate.instant('retained_days_error'));
      return false;
    }

    if (noIdentificationChecked()) {
      NotificationService.Warning(
        $translate.instant('mandatory_at_least_one_id')
      );
      return false;
    }

    if (!requirements.identifications.find((id) => id.default)) {
      NotificationService.Warning(
        $translate.instant('mandatory_at_least_one_default_id')
      );
      return false;
    }

    if (!lineFlowTypeOrganization) {
      NotificationService.Warning($translate.instant('mandatory_call_logic'));
      return false;
    }

    if (isNil(sla) || sla < 0 || sla > 1000) {
      NotificationService.Warning($translate.instant('sla_place_invalid'));
      return false;
    }

    if (isNil(checkInMin) || checkInMin < 1 || checkInMin > 10000) {
      NotificationService.Warning(
        $translate.instant('error_pre_check_in_invalid_range')
      );
      return false;
    }

    if (isNil(checkInMax) || checkInMax < 1 || checkInMax > 10000) {
      NotificationService.Warning(
        $translate.instant('error_post_check_in_invalid_range')
      );
      return false;
    }

    return true;
  };

  $scope.validateSpontaneousAndCheckinButton = (prop) => {
    if (prop === 'canConfirmSpontaneous') {
      if (
        !$scope.place.configuration.canConfirmSpontaneous &&
        !$scope.place.mobileAppConfig.showCheckInButton
      ) {
        $scope.place.mobileAppConfig.showCheckInButton = true;
        NotificationService.Information(
          $translate.instant('set_showCheckInButton'),
          { timeOut: 10000 }
        );
      }
    }
    if (prop === 'showCheckInButton') {
      if (
        !$scope.place.mobileAppConfig.showCheckInButton &&
        !$scope.place.configuration.canConfirmSpontaneous
      ) {
        $scope.place.configuration.canConfirmSpontaneous = true;
        NotificationService.Information(
          $translate.instant('set_canConfirmSpontaneous'),
          { timeOut: 10000 }
        );
      }
    }
  };

  $scope.save = () => {
    if (validate()) {
      $scope.blockSaveButton = true;
      $scope.place.categories = $scope.data.selectedCategory.map(
        (category) => category.key
      );
      $scope.place.info.phones[0] = $scope.data.phone;
      if (!$scope.place.branchId) {
        Reflect.deleteProperty($scope.place, 'branchId');
      }

      if (!$scope.place.checkInCode) {
        Reflect.deleteProperty($scope.place, 'checkInCode');
      }

      $scope.place.requirements.identifications.forEach(function(id) {
        Reflect.deleteProperty(id, 'validator');
      });

      if (placeId) {
        Reflect.deleteProperty($scope.place, 'userAdmin');
        $scope.place.configuration.display.background = $scope.displayBackground;
        $scope.place.configuration.display.color = $scope.displayColor;
        $scope.place.configuration.totem.background = $scope.totemBackground;
        $scope.place.configuration.totem.color = $scope.totemColor;
        return PlaceService.UpdateWithCallLogic(
          $scope.place,
          $scope.editCallLogicsScope.selectedOption
        )
          .then(() => {
            if ($scope.file.hasOwnProperty('logo')) {
              saveImages($scope.place._id, $scope.file);
            }
            NotificationService.Success($translate.instant('changes_saved'));
            $uibModalInstance.close('cancel');
          })
          .catch((error) => {
            ErrorService.handler(error);
            $scope.blockSaveButton = false;
          });
      } else {
        $scope.place.userAdmin.local.password = AuthService.Encrypt(
          $scope.place.userAdmin.local.password
        );
        return PlaceService.Create($scope.place)
          .then(() => {
            $timeout(() => {
              NotificationService.Success($translate.instant('stablish_succ'));
              $uibModalInstance.close('cancel');
            });
          })
          .catch((error) => {
            ErrorService.handler(error);
            $scope.blockSaveButton = false;
          });
      }
    }
  };

  const saveImages = (id, files) => {
    NotificationService.Information($translate.instant('uploading_images'));
    return PlaceService.SaveImages(id, files)
      .then(() =>
        NotificationService.Information($translate.instant('updated_images'))
      )
      .catch(() =>
        NotificationService.Error($translate.instant('err_update_image'))
      );
  };

  $scope.cancel = () => {
    dialog
      .confirm($translate.instant('modal_cancel'))
      .then(() => $uibModalInstance.dismiss('cancel'))
      .catch(() => 'canceled');
  };

  $scope.checkAliasType = () => {
    if (!$scope.place.requirements.id) {
      $scope.place.configuration.aliasType = 'fullNameAlias';
      NotificationService.Information(
        $translate.instant('alias_has_been_changed')
      );
    }
  };

  // Location
  const findByStablishment = () => {
    if (
      $scope.data &&
      $scope.data.address &&
      $scope.data.address.length >= 3 &&
      !$scope.data.selectedPlace
    ) {
      return LocationService.FindStablishments($scope.data.address).then(
        (result) => {
          $timeout(() => {
            $scope.data.results = result;
          });
        }
      );
    }
  };

  const findByAddress = () => {
    let search = $scope.data ? $scope.data.addressSearch : null;
    if (search && search.length >= 3 && !$scope.data.selectedAddress) {
      return LocationService.FindAddresses(search).then((result) => {
        $timeout(() => {
          $scope.data.addressResults = result;
        });
      });
    }
  };

  $scope.ttsEnabledChange = () => {
    if ($scope.place.configuration.display.ttsEnabled.enabled) {
      $scope.place.configuration.display.ttsEnabled.enabledFor = 'all';
    }
  }

  $scope.changeEnabledFor = (value) => {
    $scope.place.configuration.display.ttsEnabled.enabledFor = value;
  }

  $scope.selectPlace = (place) => {
    if (place.place_id) {
      // Set description on search input
      if (place.description) {
        $scope.data.address = place.description;
      }

      // Get the details of the place selected by user
      LocationService.GetPlaceDetails(place.place_id).then((placeDetails) => {
        $timeout(async () => {
          $scope.data.selectedPlace = placeDetails;
          $scope.data.locationAsString = [
            placeDetails.geometry.location.lat(),
            placeDetails.geometry.location.lng(),
          ];
          $scope.place.location = {
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
          };
          await autocompleteFields(placeDetails);
        });
      });
    }

    $scope.data.results = [];
  };

  $scope.selectAddress = (address) => {
    const googlePlaceId = address.place_id;
    if (googlePlaceId) {
      // Set description on search input
      if (address.description) {
        $scope.data.addressSearch = address.description;
      }

      // Get the details of the place selected by user
      LocationService.GetPlaceDetails(googlePlaceId).then((placeDetails) => {
        $timeout(async () => {
          $scope.data.selectedAddress = placeDetails;
          $scope.place.location = {
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
          };
          await autocompleteFields(placeDetails, true);
        });
      });
    }
    $scope.data.addressResults = [];

    $timeout(async () => await setCountryAndIdentifications($scope.place));
  };

  $scope.$watch('data.address', debounce(findByStablishment, 500));
  $scope.$watch('data.addressSearch', debounce(findByAddress, 500));

  // Place info
  /* eslint-disable camelcase */
  async function autocompleteFields(placeDetails, excludeName) {
    const {
      name,
      website,
      formatted_phone_number,
      formatted_address,
      place_id,
    } = placeDetails;

    if (name && !excludeName) {
      $scope.place.name = name;
    }

    $scope.place.info.website = website ? website : organization.website;
    $scope.data.phone = formatted_phone_number
      ? formatted_phone_number
      : organization.phoneNumber;

    if (formatted_address) {
      $scope.place.info.address = formatted_address;
    }

    if (placeDetails.opening_hours) {
      $scope.place.openingDetails.periods = placeDetails.opening_hours.periods;
      $scope.place.openingDetails.weekday_text =
        placeDetails.opening_hours.weekday_text;
    }

    // Google place id
    $scope.place.googlePlaceId = place_id;
    /* eslint-enable camelcase */

    // Check in code
    $scope.place.checkInCode = `${Slug.slugify(placeDetails.name)}_check_in`;

    // Address components
    Object.assign(
      $scope.place.info,
      LocationService.GetLocationInfo(placeDetails)
    );

    // Timezone
    $scope.place.timezone = tzlookup(
      placeDetails.geometry.location.lat(),
      placeDetails.geometry.location.lng()
    );

    await setCountryAndIdentifications($scope.place);
  }

  $scope.restartSelectedPlace = () => {
    $scope.data.results = [];
    $scope.data.selectedPlace = '';
    $scope.data.address = '';
    $scope.data.locationAsString = '';
    $scope.data.addressSearch = '';
    $scope.data.addressResults = [];
    $scope.data.selectedAddress = '';

    if (!placeId) {
      let userAdmin = $scope.place.userAdmin;
      $scope.place = cloneDeep(emptyPlace);
      $scope.place.userAdmin = userAdmin;
      $scope.status = {
        ...$scope.status,
        location: {
          isOpen: true,
          isDisabled: false,
        },
        information: {
          isOpen: false,
          isDisabled: true,
          shouldNameBeFocused: false,
        },
        configuration: {
          isOpen: false,
          isDisabled: true,
        },
      };
    }
  };

  $scope.checkIfUserExists = (email) => {
    UserService.CheckIfExists(email).then((res) => {
      $timeout(() => {
        const user = res[0];
        if (user) {
          $scope.userExists = true;
          $scope.place.userAdmin = {
            _id: user._id,
            local: {
              email: user.local.email,
            },
            profile: {
              firstName: user.profile.firstName,
              lastName: user.profile.lastName,
            },
          };
          NotificationService.Information(
            'El usuario existe y se ha autocompletado.'
          );
        } else {
          $scope.userExists = false;
          Reflect.deleteProperty($scope.place.userAdmin, '_id');
          NotificationService.Information(
            'El usuario no existe, por favor completá los siguientes campos.'
          );
        }
        $scope.canComplete = true;
      });
    });
  };

  $scope.emailChanged = () => {
    if ($scope.canComplete) {
      $scope.place.userAdmin = {
        local: {
          email: '',
          password: '',
        },
        profile: {
          firstName: '',
          lastName: '',
        },
      };
      $scope.userExists = true;
      $scope.canComplete = false;
    }
  };

  $scope.checkStep = {
    user: () => {
      if ($scope.forms.userPlace.$valid && !placeId) {
        $scope.status = {
          ...$scope.status,
          user: {
            isOpen: false,
          },
          location: {
            isOpen: true,
            isDisabled: false,
          },
        };
      }
    },
    location: () => {
      if ($scope.data.selectedPlace || $scope.status.noPlace) {
        $scope.status = {
          ...$scope.status,
          location: {
            isOpen: false,
            isDisabled: false,
          },
          information: {
            isOpen: true,
            isDisabled: false,
            shouldNameBeFocused: true,
          },
          configuration: {
            isDisabled: true,
          },
        };
      }
      if (placeId) {
        $scope.status.showTooltip = false;
      }
    },
    information: () => {
      if ($scope.forms.informationPlace.$valid) {
        $scope.status = {
          ...$scope.status,
          information: {
            isOpen: false,
            isDisabled: false,
            shouldNameBeFocused: false,
          },
          configuration: {
            isOpen: true,
            isDisabled: false,
          },
        };
      }
      if (placeId) {
        $scope.status.showTooltip = true;
        $scope.status.information.isDisabled = true;
      }
    },
  };

  async function setCountryAndIdentifications(place) {
    const location = (await LocationService.Find(place.info.address))[0];

    const countryFound = await CountryService.FindByCode(location.code);

    const country =
      countryFound ||
      (await CountryService.Create({
        code: location.code,
        name: location.country,
      }));

    $scope.place.location.country = country._id;
    $scope.place.location.countryCode = country.code;
    $scope.country = country.name;
    $scope.place.requirements.identifications = mergeCountryIdentifications(
      country.identifications,
      get(place, 'requirements.identifications', [])
    );

    $scope.placeIdentifications = [
      ...$scope.place.requirements.identifications,
    ];

    $scope.setDefaultIndentificationsList();
  }

  function mergeCountryIdentifications(
    countryIdentifications,
    placeIdentifications
  ) {
    return countryIdentifications.map((identification) => {
      identification.show = identification.selected;
      Reflect.deleteProperty(identification, 'selected');
      return placeIdentificationOrDefault(placeIdentifications, identification);
    });
  }

  function placeIdentificationOrDefault(placeIdentifications, identification) {
    const placeIdentification = placeIdentifications.find(
      (p) => p.key === identification.key
    );
    return Object.assign({}, identification, placeIdentification);
  }

  $scope.autoCheckInChange = (value) => {
    $scope.place.mobileAppConfig.showCheckInButton = !value;
    if (value) {
      $scope.place.mobileAppConfig.showQRCode = false;
      $scope.place.configuration.canConfirmSpontaneous = false;
    }
  };

  $scope.uploadFile = (files, type) => {
    if (files.length > 0) {
      const ext = files[0].name.match(/\.(.+)$/)[1];
      $timeout(() => {
        $scope.file[type] = files[0];
      });
      if (
        !angular.lowercase(ext) === 'jpg' ||
        !angular.lowercase(ext) === 'jpeg' ||
        !angular.lowercase(ext) === 'png'
      ) {
        NotificationService.Warning($translate.instant('invalid_file_format'));
      }
    }
  };

  $scope.deleteImage = (type) => {
    const defaultImage = 'img/common/placeholder-place.png';
    $timeout(() => {
      $scope.file[type] = null;
      switch (type) {
        case 'logo':
          $scope.logoSrc = defaultImage;
          break;
        default:
          break;
      }
    });
  };

  $scope.changeDisplayColor = (value, type) => {
    if (type === 'background') {
      $scope.displayBackground = value;
    } else {
      $scope.displayColor = value;
    }
  };

  $scope.changeTotemColor = (value, type) => {
    if (type === 'background') {
      $scope.totemBackground = value;
    } else {
      $scope.totemColor = value;
    }
  };

  function checkDesktopCallerPermissions(user) {
    if (!user) {
      return false;
    }
    if (user.sudo) {
      return true;
    }
    for (const permission of user.permissions) {
      if (permission.role) {
        const role = permission.role;
        if (role.name === 'Whyline - SuperUser') {
          return true;
        }
        if (role.name === 'Organization Admin' && role.organizationId === organization._id) {
          return true;
        }
        if (role.name === 'Admin General' && role.placeId === placeId) {
          return true;
        }
      }
    }
    return false;
  }
};

module.exports = module.controller(
  'PlaceModalController',
  PlaceModalController
);
