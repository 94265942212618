import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/display';
import '../resources/area';
import '../resources/line';
import '../resources/banner';

const module = angular.module('whyline.services.display', [
  'whyline.resources.display',
  'whyline.resources.area',
  'whyline.resources.line',
  'whyline.resources.banner'
]);

let Display;
let Area;
let Line;
let Banner;

const handleError = err => {
  throw err;
};

class DisplayService {

  static $inject = ['Display', 'Area', 'Line', 'Banner'];

  constructor(injectedDisplay, injectedArea, injectedLine, injectedBanner) {
    Display = injectedDisplay;
    Area = injectedArea;
    Line = injectedLine;
    Banner = injectedBanner;
  }

  Create(data) {
    const newDisplay = new Display(data);
    return Display.Save(newDisplay);
  }

  Copy(display) {
    const copy = cloneDeep(display);
    return new Display(copy);
  }

  Update(display) {
    return Display.Save(display);
  }

  Remove(displayId) {
    const display = Display.FindById(displayId);
    return Display.Remove(display);
  }

  Identify(displayId) {
    return Display.Identify(displayId);
  }

  MinMax(displayId) {
    return Display.MinMax(displayId);
  }

  Refresh(displayId) {
    return Display.Refresh(displayId);
  }

  Switch() {
    //console.log('TODO: implement display switch');
  }

  GetAll() {
    return Display.FetchAll();
  }

  GetAllAsPromise() {
    return Display.FetchAll({ promise: true});
  }

  GetAllAsPromiseFromServer() {
    return Display.FetchAll({ promise: true, force: true});
  }

  GetOne(displayId) {
    return Display.FindById(displayId);
  }

  GetOneAsPromise(displayId) {
    return Display.FindById(displayId, {promise: true});
  }

  GetOnePopulated(displayId) {
    return Area.FetchAll({ promise: true })
    .then(() => Line.FetchAll({ promise: true }))
    .then(() => Banner.FetchAll({ promise: true }))
    .then(() => Display.FindById(displayId, {
      populate: ['lines', 'areas', 'users', 'banners'],
      promise: true
    }))
    .catch(handleError);
  }

  GetOnePopulatedAsPromiseFromServer(displayId) {
    return Area.FetchAll({ promise: true })
    .then(() => Line.FetchAll({ promise: true }))
    .then(() => Banner.FetchAll({ promise: true }))
    .then(() => Display.FindById(displayId, {
      populate: ['lines', 'areas', 'users', 'banners'],
      promise: true,
      force: true
    }))
    .then(newData => newData)
    .catch(handleError);
  }

}

module.exports = module.service('DisplayService', DisplayService);
